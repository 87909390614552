<template>
  <div>
    <div class="mb-6 is-flex is-flex-direction-column is-align-items-stretch">
      <div class="columns is-mobile">
        <div class="column has-text-left">
          <current-parking v-if="parking && parking.id" class="mb-6" />
        </div>
        <div class="column is-narrow">
          <button
            class="button icon is-ghost"
            v-if="tokenGratend === null"
            @click="logoutUserFromApp()"
          >
            <FaIcon icon="power-off" />
          </button>
        </div>
      </div>

      <router-link
        v-if="!parking || !parking.id"
        class="button is-link is-outlined mb-2"
        to="/parking/select"
      >
        {{ $t('menu.selectParking') }}
      </router-link>
      <router-link class="button is-primary is-large mb-5" to="/user/qr-code">
        {{ $t('menu.yourQr') }}
      </router-link>
      <router-link
        class="button is-primary is-large mb-5"
        :to="`/parking/reserve`"
        v-if="parking && parking.id"
      >
        {{ $t('menu.createReservation') }}
      </router-link>
      <router-link class="button is-primary mb-5" to="/user/reservations">
        {{ $t('menu.yourReservations') }}
      </router-link>
      <router-link
        class="button is-primary mb-5 is-outlined"
        :to="`/company`"
        v-if="userIs(['superadmin', 'parking_administration', 'company_admin'])"
      >
        {{ $t('menu.companyManagement') }}
      </router-link>
    </div>
    <div class="columns is-mobile">
      <div class="column has-text-left">
        <router-link class="is-half" to="/user/absence">
          {{ $t('menu.absense') }}
        </router-link>
      </div>
      <div class="column has-text-right">
        <router-link class="is-half" to="/user/vehicles">
          {{ $t('menu.yourVehicles') }}
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
import CurrentParking from '@/components/CurrentParking';
import userMixin from '@/mixins/userMixin';

export default {
  mixins: [userMixin],
  components: {
    CurrentParking,
  },
  computed: {
    ...mapGetters({
      parking: 'parking/getCurrentParking',
      tokenGratend: 'auth/getGrantedBySpaceosToken',
    }),
  },
  watch: {},
  methods: {
    ...mapMutations({
      clearTokens: 'auth/clearTokens',
    }),
    logoutUserFromApp() {
      // eslint-disable-next-line no-restricted-globals
      const validate = confirm('Are you sure you want to logout?');
      if (validate) {
        this.clearTokens();
        this.$router.push({ path: '/auth/login' });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/variables.scss';
.logo {
  height: 1.5rem;
  @include mobileAndShort {
    display: none;
  }
}
</style>
