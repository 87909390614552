// interceptor to catch errors
const errorInterceptor =
  ({ store, router, Sentry }) =>
  (error) => {
    Sentry.addBreadcrumb({
      category: 'axioserror',
      message: `Axios error${error}`,
      level: Sentry.Severity.Info,
    });
    Sentry.captureException(error);

    // check if it's a server error
    if (!error.response) {
      store.dispatch('notify', {
        title: 'Network/Server unknown error',
      });
      return Promise.reject(error);
    }

    // all the other error responses

    // all the other error responses
    switch (error.response.status) {
      case 400:
        console.error(error.response.status, error.message);
        console.log(error.response);
        store.dispatch('notify', {
          msg: error.response.data.message,
          title: 'Błąd',
        });
        break;

      case 403:
        console.error(error.response.status, error.message);
        console.log(error.response);
        store.dispatch('notify', {
          msg: error.response.data.message,
          title: 'Nie masz dostępu do tego zasobu',
        });
        break;

      case 401: // authentication error, logout the user
        if (!(error.config.meta && error.config.meta.noAuthError)) {
          store.dispatch('notify', {
            msg: 'Please login again',
            title: 'Session Expired',
          });
          store.commit('auth/clearTokens');
          if (!router.currentRoute.value.meta.doNotRedirectOnError) {
            router.push('/auth/login');
          }
        }
        break;

      default:
        console.error(error.response.status, error.message);
        if (error.response && error.response.data && error.response.data.message) {
          store.dispatch('notify', {
            title: 'Error',
            msg: error.response.data.message,
          });
        } else {
          store.dispatch('notify', {
            title: 'Network/Server error',
          });
        }
        break;
    }
    return Promise.reject(error);
  };

export default errorInterceptor;
