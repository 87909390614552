import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import { createApp } from 'vue';
import { dragscrollNext } from 'vue-dragscroll';
// import '@fortawesome/fontawesome-free/css/all.css';
import '@/assets/config.scss';
import FontAwesomeIcon from './icons';

import App from '@/App';
import { init } from '@/utils/api/httpClient';
import router from '@/router/index';

import i18n from './config/i18n';
import store from './store';

const app = createApp(App);
if (process.env.VUE_APP_SENTRY_ENABLE === '1') {
  Sentry.init({
    app,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    release: process.env.VUE_APP_VERSION || 'unknown',
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['localhost', 'my-site-url.com', /^\//],
      }),
    ],
    tracesSampleRate: process.env.VUE_APP_SENTRY_SAMPLE_RATE
      ? Number(process.env.VUE_APP_SENTRY_SAMPLE_RATE)
      : 0.2,
    logErrors: process.env.VUE_APP_SENTRY_LOG_ERROR === 1,
  });
}

app.use(router);
app.use(i18n);
app.component('FaIcon', FontAwesomeIcon);
app.use(store);
app.directive('dragscroll', dragscrollNext);
init({
  store,
  router,
  Sentry,
});
app.mount('#app');
