<template>
  <perfect-scrollbar class="scrollbar" :class="{ noHeight }">
    <slot />
  </perfect-scrollbar>
</template>
<script>
import { PerfectScrollbar } from 'vue3-perfect-scrollbar';

export default {
  components: {
    PerfectScrollbar,
  },
  props: {
    noHeight: Boolean,
  },
  data: () => ({
    dragged: false,
    dragTimeout: null,
  }),
  methods: {
    // onDragStart() {
    //   clearTimeout(this.dragTimeout);
    //   this.dragged = false;
    //   this.dragTimeout = setTimeout(() => {
    //     this.dragged = true;
    //   }, 100); // Minimal delay to be regarded as drag instead of click
    // },
    // onDragClick(e) {
    //   if (this.dragged) {
    //     e.preventDefault();
    //     e.stopPropagation();
    //   }
    //   this.dragged = false;
    // },
  },
};
</script>
<style lang="scss" scoped>
.scrollbar:not(.noHeight) {
  height: calc(var(--app-height) * 0.8);
}
::v-deep .ps__rail-y {
  opacity: 0.6;
}
</style>
<style src="vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css" />
