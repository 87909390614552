// https://stackoverflow.com/a/67261660
import { createI18n } from 'vue-i18n/index';

const messages = {
  en: {
    menu: {
      yourQr: 'Your QR code',
    },
    Vehicle: {
      checkLicencePlate: 'Licence plate number cannot be empty',
      checkCarName: 'Car name cannot be empty',
    },
  },
  pl: {
    menu: {
      confirm: 'Zatwierdz',
      absense: 'Dni nieobecności',
      yourQr: 'Kod QR do wjazdu',
      companyManagement: 'Zarządzaj firmą',
      selectParking: 'Wybierz parking',
      scanYourCode: 'Zeskanuj go przy wjeździe na parking',
      yourReservations: 'Twoje rezerwacje',
      yourVehicles: 'Twoje pojazdy',
      selectOther: 'zmień',
      createReservation: 'Zarezerwuj miejsce',
      adminPanel: 'Administracja',
    },
    company: {
      users: 'Użytkownicy',
      id: 'id',
      function: 'rola'
    },
    absence: {
      add: 'Dodaj nieobecność',
      pickDate: 'Wybierz zakres dat:',
      return: 'Wróć',
      title: 'Możesz dodać dni nieobecności',
      info: 'Wówczas podaczas nieobecności inni uzytkownicy będą mogli korzystać z zarezerwowanego miejsca.',
      assignTo: 'Przypisz do:'
    },
    addRes: {

    },
    slotDetails: {
      type: 'Typ:',
      createPlace: 'Utwórz miejsce parkingowe',
      create: 'Utwórz',
      changeTypeOfSpace: 'Zmień rodzaj przestrzeni',
      road: 'Road',
      building: 'Building',
      entrance: 'Wejscie',
      exit: 'Exit',
      delete: 'Usuń miejsce parkingowe',
      showWay: 'Pokaż trasę',
    },
    qr: {
      yourQr: 'Twój osobisty kod QR',
      info: 'Uwaga: kod QR upoważnia do wjazdu tylko osoby z ważną rezerwacją.'
    },
    parkings: {
      title: 'Wybierz parking:',
      helper: 'Jeśli nie widzisz parkingu, poproś administratora w Twojej firmie o udostępnienie.',
      canEnter: 'Zezwól na wjazd bez rezerwacji',
    },
    helpers: {
      addVehicle: {
        title: 'Rozpoznawanie tablic',
        description:
          'Jeśli nie chcesz przy wjeździe skanować kodu, dodaj tablice rejestracyjne swojego pojazdu.',
        button: 'Moje pojazdy',
      },
      yes: 'Tak',
      no: 'Nie',
      name: 'Nazwa',
      role: 'Rola',


    },
    Vehicle: {
      title: 'Dodaj pojazd',
      description: 'aby wjeżdżać bez skanowania kodu QR',
      button: 'Dodaj Pojazd',
      save: 'Zapisz',
      hide: 'Ukryj',
      cancel: 'Anuluj',
      delete: 'Usuń',
      confirm: 'Potwierdzam',
      plate: 'Podaj numer tablicy rejestracyjnej aby dodać samochód',
      checkLicencePlate: 'Numer tablicy rejestracyjnej nie moze być pusty',
      checkCarName: 'Nazwa pojazdu nie moze być pusta',
    },
    time: {
      from: 'od',
      to: 'do',
      h: 'g',
      m: 'm',
    },
    reserv: {
      noAcces: 'Niestety nie masz dostępu do żadnych miejsc parkingowych.',
      askAdmin: ' Poproś administratora w twojej firmie o udostępnienie.'

    },
    selectListItems: {
      users: 'Użytkownicy',
      recurentRes: 'Rezerwacje rekurencyjne',
    },

    reservations: {
      reservation: 'Rezerwacja',
      nameOfLocation: 'Nazwa Budynku',
      timeShortCut: 'godz',
      guest: 'gość',
      noGuest: 'brak gościa',
      addHandler: 'Wybierz godzinę dla danego miejsca.',
      add: 'Zarezerwuj',
      addTitle: 'Rezerwacja miejsca parkingowego',
      addHelper: 'Wybierz godziny',
      addGuest: 'Dodaj gościa',
      editGuest: 'Edytuj gościa',
      askAddGuest: 'Czy chcesz Dodać gościa?',
      succesful: 'Rezerwacja Udana',
      delete: 'Anuluj rezerwację',
      status: 'status',
      slotNumber: 'nr. miejsca',
      noReservations: 'Brak rezerwacji',
      floor: 'piętro',
      ready: 'Gotowe',
      statuses: {
        reserved: 'zarezerwowano',
        reserved_cancelled: 'anulowano',
        entering: 'wjazd',
        entered: 'wjechano',
        exiting: 'wyjazd',
        exited: 'wyjechano',
      },
      repeat: {
        day: 'dzień',
        week: 'tydzień',
        every: 'co',
        everyday: 'codziennie',
        none: 'brak',
      },
    },
    guest: {
      title: 'Dodaj gościa',
      subtitle: 'Wyślemy mu zaproszenie emailem',
      addedTitle: 'Gość dodany!',
      addedSubtitle: 'Wysłaliśmy mu emailem informacje jak skorzystać z miejsca parkingowego',
      addGuestMsg: 'Dodaj gościa, wyślemy mu zaproszenie emailem',
      noReservations: 'Tutaj pojawią się Twoje reserwacje.',
    },
    login: {
      title: 'Dobrze Cię widzieć',
      subtitle: 'Zaloguj się aby kontynuować',
      loginWith: 'zaloguj się uzywając:',
    },
    invitation: {
      level: 'Poziom',
      floors: 'Piętro',
      parkOn: 'Zaparkuj na miejscu nr. ',
      description: 'Wpisz swoje numery tablic rejestracyjnych, aby wjechać bez skanowania kodu',
      title: 'Zeskanuj kod QR Przy wieździe na parking',
      share: 'Udostępnienie miejsca parkingowego',
      scan: 'Zeskanuj kod przy wjeździe:',
      addlicencePlate: 'Możesz dodać swoje tablice rejestracyjne, aby wjechać bez skanowania kodu.',
      error: 'Niestety nie znaleźliśmy takiego zaproszenia. Upewnij się, że link jest prawidłowy',
      contact: 'W razie problemów skontaktuj się z osobą zapraszającą.',
      add: 'Dodaj',
    },
    error: {
      404: 'Zdarzyło się coś nieoczekiwanego!',
    },
    admin: {
      adminPanel: 'admin panel',
      users: 'użytkownicy',
      company: 'firma',
      assignCompany: 'Przypisz firme do miejsca',
      choose: 'Wybierz',
      changeDirection: ' Zmień kierunek',
      yes: 'Tak',
      no: 'Nie',
      parking: 'parking'
    },
    users: {
      id: 'Id',
      name: 'Nazwa',
      email: 'Email',
      role: 'Rola',
      enterWithoutReservation: 'wolny wstęp',
      company: 'Firma',
      info: 'Otwarta kłódka przy użytkowniku oznacza, że może on wjeżdzać na parking bez tworzenia rezerwacji. Kliknij w nią aby to zmienić.',
      plateNumber: 'Nr.rej',
      temporaryLicencePlateInfo: 'Tymczasowy Numer Rejstracyjny'
    },

  },
};

const i18n = createI18n({
  locale: 'pl', // set locale
  fallbackLocale: 'en', // set fallback locale
  messages, // set locale messages
});

export default i18n;
