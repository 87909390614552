import { getLots } from '@/utils/api/parking_lot';

export default {
  state() {
    return {
      lots: [],
      loaded: false,
      loading: false,
    };
  },
  mutations: {
    setLots: (state, lots) => {
      state.lots = lots;
      state.loaded = true;
    },
    setLoading: (state, loading) => {
      state.loading = loading;
    },
  },
  getters: {
    getLoading: (state) => state.loading,
    getLoaded: (state) => state.loaded,
    getLots: (state) => state.lots,
    getLotById: (state) => (lotId) => state.lots.find((e) => e.id === lotId),
  },
  actions: {
    async fetchLots({ commit }) {
      commit('setLoading', true);
      const response = await getLots();
      commit('setLots', response.data);
      commit('setLoading', false);
      return response.data;
    },
    async fetchLotsIfEmpty({ dispatch, getters }) {
      if (!getters.getLoading && !getters.getLoaded) {
        return dispatch('fetchLots');
      }
      return getters.getLots;
    },
  },
  namespaced: true,
};
