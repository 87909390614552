import moment from 'moment';
import m from './math';

export default {
  toTime(val, units = false) {
    let hour = Math.floor(val);
    let mins = ((Math.floor(val * 100) % 100) / 100) * 60;
    if (units) {
      let ret = '';
      if (hour) ret += `${hour}${this.$t('time.h')}`;
      if (hour && mins) ret += ' ';
      if (mins) ret += `${mins}${this.$t('time.m')}`;
      return ret;
    }
    if (hour < 10) hour = `0${hour}`;
    if (mins < 10) mins = `0${mins}`;
    return `${hour}:${mins}`;
  },
  toNum(time, fraction = false, direction = 'up') {
    const t = moment.duration(time).asHours();
    if (fraction === false) return t;
    if (direction === 'up') {
      return m.ceilToFraction(t, fraction);
    }
    if (direction === 'down') {
      return m.floorToFraction(t, fraction);
    }
    throw new Error('Direction must be up or down');
  }
};
