<template>
  <user-vehicles />
</template>
<script>
import UserVehicles from '@/components/UserVehicles';

export default {
  components: {
    UserVehicles,
  },
};
</script>
