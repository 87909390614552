<template>
  <div>
    <div>
      <div class="columns is-mobile">
        <div class="column is-half ml-1 mt-3">
          <div class="field">
            <div class="control">
              <input
                class="input"
                v-model="filterby"
                type="text"
                :placeholder="'Search by ' + searchBy + ' adress'"
              />
            </div>
          </div>
        </div>

        <div class="column is-half ml-1 mt-4 has-items-centered">
          <template v-for="(item, idx) in searchByValues" :key="idx">
            <label class="radio">
              <input type="radio" name="answer" :value="item" v-model="searchBy" />
              {{ idx }}
            </label>
          </template>
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column" :class="{}">
        <ScrollBox>
          <div class="table-container mr-1">
            <table class="table">
              <thead>
                <tr>
                  <th @click="sortby = 'id'">{{ $t('users.id') }}</th>
                  <th class="width:50px" @click="sortby = 'name'">
                    {{ $t('users.name') }}
                  </th>
                  <th @click="sortby = 'email'">{{ $t('users.email') }}</th>
                  <th @click="sortby = 'plateNumber'">{{ $t('users.plateNumber') }}</th>
                  <th @click="sortby = 'role'">{{ $t('users.role') }}</th>
                  <th
                    @click="sortby = 'allowEntryWithoutReservation'"
                    class="is-narrow"
                    title="no reservation requied"
                  >
                    {{ $t('users.enterWithoutReservation') }}
                  </th>
                  <th @click="sortby = 'spaceosCompanyId'" title="Company">
                    {{ $t('users.company') }}
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="(user, idx) in sortedArray"
                  :key="idx"
                  @click="selectedUser = user"
                  v-bind:class="{ 'is-selected': selectedUser.id === user.id }"
                >
                  <td @click="height = 20">{{ user.id }}</td>
                  <td>{{ user.name }}</td>
                  <td>{{ user.email }}</td>
                  <td>
                    <template v-for="(item, idx) in user.__vehicle_plates__" :key="idx">
                      {{ item.plateNumber }}
                      <FaIcon
                        v-show="~item.isPlateNumberTemporary"
                        icon="fa-light clock"
                        class="is-grey-lighter"
                        :aria-label="$t('users.temporaryLicencePlateInfo')"
                      />
                      <br />
                    </template>
                  </td>
                  <td>{{ user.role }}</td>

                  <td>
                    <div class="icon">
                      <FaIcon :icon="user.allowEntryWithoutReservation && 'check'" />
                    </div>
                  </td>
                  <td>
                    {{
                      user.spaceosCompanyId &&
                      getCompanyById(user.spaceosCompanyId).spaceos_company_name
                    }}
                  </td>
                </tr>
                <tr>
                  <SpinLoader v-if="loading"></SpinLoader>
                </tr>
              </tbody>

              <tfoot v-if="!loading">
                <td title="id">{{ $t('users.id') }}</td>
                <td title="name">{{ $t('users.name') }}</td>
                <td title="email">{{ $t('users.email') }}</td>
                <td title="plateNumber">{{ $t('users.plateNumber') }}</td>
                <td title="role">{{ $t('users.role') }}</td>
                <td title="can">{{ $t('users.enterWithoutReservation') }}</td>
                <td title="Company">{{ $t('users.company') }}</td>
              </tfoot>
            </table>
          </div>
        </ScrollBox>
      </div>
      <users-details
        :selectedUser="selectedUser"
        :enabled="selectedUser.allowEntryWithoutReservation"
      ></users-details>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import ScrollBox from '@/components/ui/layout/ScrollBox';
// eslint-disable-next-line no-unused-vars
import UsersDetails from '@/components/UsersDetails';
import SpinLoader from '@/components/ui/layout/Placeholders/SpinLoader';

export default {
  data() {
    return {
      sortby: 'id',
      msg: null,
      filterby: '',
      selectedUser: Object,
      loading: true,
      searchByValues: {
        name: 'name',
        email: 'email',
        plateNumber: 'plateNumber',
        role: 'role',
        company: 'company',
      },
      searchBy: 'name',
    };
  },
  components: {
    ScrollBox,
    UsersDetails,
    SpinLoader,
  },
  watch: {
    sortedArray() {
      this.loading = false;
    },
  },
  computed: {
    ...mapGetters({
      users: 'admin/getUsers',
      companies: 'companies/getCompanies',
    }),

    sortedArray() {
      if (!this.sortby) {
        return this.filteredArray;
      }
      if (this.sortby === 'id') {
        return this.filteredArray.slice().sort((a, b) => a.id - b.id);
      }

      return this.filteredArray
        .slice()
        .sort((a, b) => `${a[this.sortby]}`.localeCompare(b[this.sortby]));
    },
    filteredArray() {
      if (!this.filterby) {
        return this.users;
      }
      if (this.searchBy === 'email') {
        return this.users
          .slice()
          .filter((e) => `${e.email}`.toLowerCase().includes(this.filterby.toLowerCase()));
      }
      if (this.searchBy === 'name') {
        return this.users
          .slice()
          .filter((e) => `${e.name}`.toLowerCase().includes(this.filterby.toLowerCase()));
      }
      if (this.searchBy === 'role') {
        return this.users
          .slice()
          .filter((e) => `${e.role}`.toLowerCase().includes(this.filterby.toLowerCase()));
      }
      if (this.searchBy === 'company') {
        return this.users
          .slice()
          .filter((e) => `${e.company}`.toLowerCase().includes(this.filterby.toLowerCase()));
      }
      return this.users;
    },
  },

  props: {
    loader: Boolean,
  },
  methods: {
    ...mapActions({
      fetchUsers: 'admin/fetchUsers',
      fetchCompanies: 'companies/fetchCompanies',
    }),
    fiterByColumn() {},
    getCompanyById(id) {
      return this.companies.find((e) => e.id === id) || {};
    },
  },
  mounted() {
    this.fetchUsers();
    this.fetchCompanies();
  },
};
</script>

<style lang="scss" scoped>
.tableFixHead {
  overflow-y: auto;
  height: 106px;
}
.tableFixHead thead th {
  position: sticky;
  top: 0;
}
.tbody {
  height: 100%;
}
table {
  border-collapse: collapse;
  width: 100%;
}
th,
td {
  padding: 8px 16px;
  border: 1px solid #ccc;
}
th {
  background: #eee;
}
</style>
