<template>
  <router-view> </router-view>
</template>

<script>
import { disablePageScroll, addFillGapSelector, addLockableSelector } from 'scroll-lock';

export default {
  components: {},
  computed: {},
  mounted() {
    addLockableSelector('.media-content');
    addFillGapSelector('.media-content');
    addLockableSelector('html');
    addFillGapSelector('html');
    disablePageScroll();
    const appHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty('--app-height', `${window.innerHeight}px`);
    };
    window.addEventListener('resize', appHeight);
    appHeight();
  },
};
</script>
