<template>
  <div class="is-flex is-flex-direction-column is-justify-content-stretch h-full is-clipped">
    <div>
      <h2 class="title is-5 pt-1 mt-4">{{ $t('Vehicle.title') }}</h2>
      <p class="pb-2">{{ $t('Vehicle.description') }}</p>
    </div>
    <ScrollBox class="is-flex-grow-1">
      <Skeleton v-if="!loaded" :elements="['icon-text']"> </Skeleton>
      <template v-else>
        <CarBox
          v-for="(plate, idx) in items"
          :plate="plate"
          :key="idx"
          class="mx-1"
          @delete="deletePlate(plate.id)"
        >
        </CarBox>
      </template>
    </ScrollBox>
    <div class="columns is-half is-mobile">
      <div class="column has-text-left">
        <slot> </slot>
        <GoBack v-if="!$slots.default"></GoBack>
      </div>
      <div class="column has-text-right">
        <button class="button has-text-right is-primary mb-1" @click="openModal = !openModal">
          {{ $t('Vehicle.button') }}
        </button>
      </div>
    </div>
  </div>

  <Modal v-model="openModal">
    <div>
      <p class="pb-2">{{ $t('Vehicle.plate') }}</p>

      <p v-if="msg" class="help is-danger">{{ msg }}</p>
      <div class="field">
        <input
          class="input"
          v-model="newCar.licencePlate.value"
          :class="newCar.licencePlate.isValid ? '' : 'is-danger'"
          :disabled="loading"
          type="text"
          placeholder="Numer rejstracyjny"
        />
      </div>
      <div class="columns is-mobile">
        <div class="column is-half has-text-left">
          <button class="button is-primary is-outlined mb-1 has-text-right" @click="closeForm">
            {{ $t('Vehicle.cancel') }}
          </button>
        </div>

        <div class="column is-half has-text-right">
          <button
            class="button is-primary has-text-right"
            :class="{ 'is-loading': loading }"
            :disabled="loading"
            @click="saveCar"
          >
            {{ $t('Vehicle.save') }}
          </button>
        </div>
      </div>
    </div>
  </Modal>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import Skeleton from '@/components/ui/layout/Placeholders/Skeleton';
import ScrollBox from '@/components/ui/layout/ScrollBox';
import Modal from '@/components/ui/layout/Modal';
import GoBack from '@/components/ui/GoBack';
import CarBox from '@/components/CarBox';
import { addVehiclePlate, deleteVehiclePlate } from '@/utils/api/vehicle_plate';

export default {
  components: {
    Modal,
    GoBack,
    CarBox,
    ScrollBox,
    Skeleton,
  },
  data() {
    return {
      openModal: false,
      newCar: {
        licencePlate: {
          value: '',
          isValid: true,
        },
      },
      editIdx: null,
      msg: null,
      loading: false,
    };
  },

  computed: {
    ...mapGetters({ user: 'auth/user/getUser', loaded: 'auth/user/getLoaded' }),

    items() {
      // eslint-disable-next-line no-underscore-dangle
      return this.user ? this.user.__vehicle_plates__ : [];
    },
  },
  methods: {
    ...mapActions({ fetchUser: 'auth/user/fetchUser' }),

    async deletePlate(id) {
      // eslint-disable-next-line no-restricted-globals
      const confirmed = confirm('Are you sure you want to delete Plate?');
      if (confirmed) {
        await deleteVehiclePlate(id);

        try {
          this.msg = null;
        } catch (err) {
          console.log(err);
          this.msg = err.message;
        }
        await this.fetchUser();
      }
    },

    checkFields() {
      this.newCar.licencePlate.isValid = true;

      if (!this.newCar.licencePlate.value) {
        this.newCar.licencePlate.isValid = false;
        this.msg = this.$t('Vehicle.checkLicencePlate');
        return false;
      }
      return true;
    },

    closeForm() {
      this.newCar = {
        licencePlate: {
          value: '',
          isValid: true,
        },
      };
      this.msg = null;

      this.openModal = false;
    },
    async saveCar() {
      if (!this.checkFields()) return;
      try {
        this.loading = true;
        await addVehiclePlate(this.newCar.licencePlate.value);

        this.msg = null;
      } catch (err) {
        console.log(err);
        this.msg = err.message;
      }
      await this.fetchUser();
      this.loading = false;

      this.closeForm();
    },
  },

  mounted() {
    this.fetchUser();
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/variables.scss';
.car {
  &:hover {
    @extend .has-background-primary-light;
  }
  &.current {
    border: 1px solid $primary;
  }
}
</style>
