<template>
  <div class="is-mobile mb-0">
    <h1 class="title is-5 mb-3">{{ $t('reservations.succesful') }}</h1>
  </div>
  <ReservationsBox :res="res" :parking="parking" />
  <div class="columns is-mobile">
    <div class="column has-text-left">
      <button
        class="button is-primary is-outlined has-background-light is-small"
        @click="this.$emit('close')"
      >
        <span>Gotowe</span>
        <span class="icon">
          <FaIcon icon="check" />
        </span>
      </button>
    </div>
    <div class="column has-text-right">
      <button class="button is-primary has-text-left is-small" @click="this.$emit('addGuest')">
        <span>
          {{ $t('reservations.addGuest') }}
        </span>
        <span class="icon">
          <FaIcon icon="user-plus" />
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
// import moment from "moment";
import ReservationsBox from '@/components/ReservationsBox';

export default {
  components: {
    ReservationsBox,
  },
  computed: {
    ...mapGetters({ parking: 'parking/getCurrentParking' }),
  },
  props: {
    res: Object,
    guest: {
      name: String,
      licencePlate: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.parking-image {
  object-fit: cover;
  object-position: bottom;

  &.center {
    object-position: center;
  }
}
</style>
