<template>
  <ul class="tree-menu is-flex is-flex-direction-column menu-list">
    <li
      v-for="(val, key) in input"
      class="is-flex is-flex-direction-column menu-list p-1"
      :key="key"
    >
      <span v-if="['string', 'number'].includes(varType(val[1]))">
        <p menu-label class="menu-list">{{ val[0] }}: {{ ' ' }} {{ val[1] }}</p>
      </span>
      <span v-if="['null'].includes(varType(val[1]))">
        <p menu-label class="menu-list">{{ val[0] }}: {{ ' ' }} {{ 'null' }}</p>
      </span>
      <span class="list-item" v-if="['object', 'array'].includes(varType(val[1]))">
        <p class="is-size-5" @click="isOpen = ~isOpen">
          {{ val[0] }}
          <span class="icon"><FaIcon :icon="isOpen ? 'chevron-down' : 'chevron-up'" /></span>
        </p>
        <div v-if="isOpen" class="ml-6">
          <template v-if="['object', 'array'].includes(varType(val[1]))">
            <tree-menu :nodes="val[1]"></tree-menu>
          </template>
        </div>
      </span>

      <span> </span>
      <!-- <span v-if="['string', 'number'].includes(varType(val[1]))">
        <p>{{ val[0] }}: {{ val[1] }}</p>
      </span>
      <span v-if="['null'].includes(varType(val[1]))">
        <p>{{ val[0] }}: null</p>
      </span> -->
    </li>
  </ul>
</template>
<script>
export default {
  name: 'tree-menu',
  props: {
    nodes: {
      type: [String, Number, Array, Object],
      default: null,
    },
    counter: { type: Number, default: 0 },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    input() {
      if (!this.nodes) return null;
      if (typeof this.nodes === 'object')
        return Object.keys(this.nodes).map((e) => [e, this.nodes[e]]);
      return this.nodes;
    },
    type() {
      const type = typeof this.nodes;
      if (this.nodes == null) {
        return 'null';
      }

      // eslint-disable-next-line no-nested-ternary
      return type === 'object' ? (Array.isArray(this.nodes) ? 'array' : 'object') : type;
    },
    isFolder() {
      return this.nodes && this.nodes.length;
    },
  },
  methods: {
    varType(val) {
      if (val === null) {
        console.log('null');
        return 'null';
      }
      if (typeof val === 'string') {
        console.log('string');
        return 'string';
      }
      if (typeof val === 'number') {
        console.log('number');
        return 'number';
      }
      const type = typeof val;
      // eslint-disable-next-line no-nested-ternary
      return type === 'object'
        ? Array.isArray(val)
          ? 'array'
          : 'object'
        : type && console.log('type');
    },
    toggle() {
      if (this.isFolder) {
        this.isOpen = !this.isOpen;
      }
    },
    openFolder() {
      if (!this.isFolder) {
        this.$emit('open-folder', this.nodes);
        this.isOpen = true;
      }
    },
  },
};
</script>

<!-- <template>
  <div class="tree-menu is-flex is-flex-direction-column ml-1">
   <div :style="indent" @click="toggleChildren"> </div>
 eslint-disable-next-line vue/valid-v-for 
 <div v-if="showChildren"> 
    <span v-if="['string', 'number'].includes(type)">{{ data }}</span>
    <template v-else-if="type === 'array'">
      <div v-for="(val, idx) in data" class="is-flex is-flex-direction-column" :key="idx">
        <span>{{ idx }}: </span>
        <tree-menu class="ml-1" :nodes="val"> </tree-menu>
      </div>
    </template>
    <template v-else-if="type === 'object'">
      <div v-for="(val, idx) in data" class="is-flex is-flex-direction-column" :key="idx">
        <span>{{ val[0] }}: </span>
        <tree-menu class="ml-1" :nodes="val[1]"> </tree-menu>
      </div>
    </template>
    <template v-else>
      <span>{{ nodes }}</span>
    </template>
     <tree-menu v-for="node in nodes" :key="node" :nodes="node.nodes"> </tree-menu> 
 </div> 
  </div>
</template>
<script>
export default {
  props: ['nodes'],
  data() {
    return { showChildren: false };
  },
  name: 'tree-menu',
  computed: {
    type() {
      return typeof this.nodes;
    },
    data() {
      if (!this.nodes) return null;
      if (typeof this.nodes === 'object')
        return Object.keys(this.nodes).map((e) => [e, this.nodes[e]]);
      return this.nodes;

      // return { transform: `translate(${this.depth * 50}px)` };
    },
  },
  methods: {
    // toggleChildren() {
    //   this.showChildren = !this.showChildren;
    // },
  },
};
</script>
-->
