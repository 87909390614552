<template>
  <router-link to="/parking/select" class="">
    <span class="icon">
      <FaIcon icon="parking" />
    </span>
    <span class="">{{ (parking && parking.spaceos_name) || $t('parkings.title') }}</span>
    <small v-if="parking && parking.spaceos_name" class="is-link change">
      ({{ $t('menu.selectOther') }})</small
    >
  </router-link>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({ parking: 'parking/getCurrentParking' }),
  },
};
</script>
<style lang="scss" scoped>
.change {
  opacity: 0.6;
}
</style>
