<template>
  <div class="is-flex is-flex-direction-column is-justify-content-stretch h-full is-clipped">
    <div>
      <h2 class="title is-5 pt-1 mt-4">
        {{ $t('absence.title') }}
      </h2>
      <p class="pb-2">
        {{ $t('absence.info') }}
      </p>
    </div>
    <ScrollBox class="is-flex-grow-1">
      <Skeleton v-if="!loaded" :elements="['icon-text']"> </Skeleton>
      <template v-else>
        <template v-for="(absence, idx) in absenceList" :key="idx">
          <div class="box">
            <div class="columns is-mobile is-vcentered">
              <div class="column has-text-left">
                <span>{{ formatTime(absence.startDate) }} - {{ formatTime(absence.endDate) }}</span>
                <br />
                <small class="has-text-primary">{{ getUser(absence.userId).email }}</small>
              </div>
              <div class="column is-narrow has-text-right">
                <button
                  @click="deleteAbsence(absence.id)"
                  class="button is-inverted icon is-danger"
                  :disabled="loading"
                  :class="{ 'is-loading': loading }"
                >
                  <span class="icon"><FaIcon icon="trash-alt" /></span>
                </button>
              </div>
            </div>
          </div>
        </template>
      </template>
    </ScrollBox>
    <div class="columns is-half is-mobile">
      <div class="column has-text-left">
        <slot> </slot>
        <GoBack v-if="!$slots.default"></GoBack>
      </div>
      <div class="column has-text-right">
        <button
          class="button has-text-right is-primary mb-1"
          @click="openModal = true"
          :disabled="loading"
          :class="{ 'is-loading': loading }"
        >
          {{ $t('absence.add') }}
        </button>
      </div>
    </div>
    <modal v-model="openModal">
      <ScrollBox class="is-flex-grow-1">
        <div
          class="is-flex is-justify-content-center is-align-items-center is-flex-direction-column"
        >
          <div class="block">
            <small>{{ $t('absence.pickDate') }}</small>
            <date-picker
              v-model="date"
              range
              :inline="true"
              :autoApply="true"
              locale="pl"
              :enableTimePicker="false"
              :disabled="loading"
            ></date-picker>
            <span>{{ formatTime(date[0]) }} - {{ formatTime(date[1]) }}</span>
          </div>
          <div
            class="block"
            v-if="userIs(['superadmin', 'parking_administration', 'company_admin'])"
          >
            <small> {{ $t('absence.assignTo') }}</small
            ><br />
            <DropDown v-model="userId"> </DropDown>
          </div>

          <div class="columns is-mobile mt-3 w-full">
            <div class="column is-half has-text-center">
              <button class="button is-outlined" @click="openModal = false">
                {{ $t('absence.return') }}
              </button>
            </div>
            <div class="column is-half has-text-center">
              <button
                class="button is-primary"
                @click="addAbsence"
                :disabled="loading"
                :class="{ 'is-loading': loading }"
              >
                {{ $t('menu.confirm') }}
              </button>
            </div>
          </div>
        </div>
      </ScrollBox>
    </modal>
  </div>
</template>

<script>
import moment from 'moment';
import { defineAsyncComponent } from 'vue';
import { mapGetters, mapActions } from 'vuex';
import { deleteUserDaysOfAbsence, addUserDaysOfAbsence } from '@/utils/api/absence';
import GoBack from '@/components/ui/GoBack';
import Modal from '@/components/ui/layout/Modal';
import Skeleton from '@/components/ui/layout/Placeholders/Skeleton';
import ScrollBox from '@/components/ui/layout/ScrollBox';
import userMixin from '@/mixins/userMixin';
import DropDown from '@/components/UsersDropDown';

export default {
  mixins: [userMixin],
  components: {
    GoBack,
    DatePicker: defineAsyncComponent(() => import('@/components/DatePicker')),
    Modal,
    Skeleton,
    ScrollBox,
    DropDown,
  },
  computed: {
    ...mapGetters({
      absenceList: 'absence/getAbsence',
      loaded: 'absence/getLoaded',
      users: 'admin/getUsers',
    }),
  },
  watch: {},
  data() {
    return {
      date: [moment(), moment()],
      openModal: false,
      loading: false,
      userId: null,
    };
  },
  methods: {
    ...mapActions({
      fetch: 'absence/fetchAbsence',
      fetchUsers: 'admin/fetchUsersIfEmpty',
    }),

    formatTime(time) {
      return moment(time).format('YYYY-MM-DD');
    },
    getUser(id) {
      if (id === this.user.id) return this.user;
      return this.users.find((e) => e.id === id) || {};
    },

    async deleteAbsence(id) {
      this.loading = true;
      await deleteUserDaysOfAbsence(id).catch((err) => console.log(err));
      this.fetch();
      this.loading = false;
    },
    async addAbsence() {
      this.loading = true;
      const start = moment(this.date[0]).startOf('day').format('YYYY-MM-DD');
      const end = moment(this.date[0]).endOf('day').format('YYYY-MM-DD');
      await addUserDaysOfAbsence(start, end, this.userId).catch((err) => console.log(err));
      this.fetch();
      this.openModal = false;
      this.loading = false;
    },
  },
  mounted() {
    this.fetch();
    if (this.userIs(['superadmin', 'parking_administration', 'company_admin'])) {
      this.fetchUsers();
    }
  },
};
</script>
