import * as Sentry from '@sentry/vue';
import { getMe } from '@/utils/api/auth/me';

export default {
  state() {
    return {
      user: null,
      guestUser: null,
    };
  },
  mutations: {
    setUser: (state, user) => {
      if (user.role === 'guest') {
        state.guestUser = user;
      } else {
        state.user = user;
      }
    },
  },
  getters: {
    getLoaded: (state) => !!state.guestUser || !!state.user,
    getUser: (state) => state.guestUser || state.user || {},
    getRole: (state) =>
      (state.guestUser && state.guestUser.role) || (state.user && state.user.role) || 'unknown',
  },
  actions: {
    async fetchUser({ commit }) {
      const response = await getMe();
      commit('setUser', response.data);
      Sentry.setUser({ email: response.data.email });
      return response.data;
    },
    async fetchIfEmpty({ state, commit }) {
      if (!state.user) {
        const response = await getMe();
        Sentry.setUser({ email: response.data.email });
        commit('setUser', response.data);
        return response.data;
      }
      return state.user;
    },
  },
  namespaced: true,
};
