<template>
  <div class="modal is-clipped" :class="modelValue ? 'is-active' : ''">
    <div class="modal-background" @click="closeModal"></div>
    <div class="modal-content box has-background-light pt-6">
      <button
        class="button is-ghost has-text-right is-absolute close-btn"
        aria-label="close"
        @click="closeModal"
      >
        <span class="icon is-small">
          <FaIcon icon="times" />
        </span>
      </button>
      <div class="container h-full">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  props: {
    modelValue: Boolean,
  },
  methods: {
    closeModal() {
      this.$emit('update:modelValue', false);
      this.$emit('close');
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/variables.scss';
.modal-content {
  max-width: calc($mobile-box-width - 50px);
  @include mobileAndShort {
    max-width: calc(100vw - 3rem);
    max-height: calc(var(--app-height) - 3rem);
    margin: 0 !important;
  }
}
.close-btn {
  position: absolute;
  right: 0;
  top: 0;
}
</style>
