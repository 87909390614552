import {
  httpClient
} from './httpClient';

const END_POINT = '/user';

export const addVehiclePlate = (plateNumber) => {
  console.log('post user plate')
  return httpClient.run.post(`${END_POINT}/vehiclePlate`, {}, {
    params: {
      plateNumber,
    }
  })
};

export const deleteVehiclePlate = (id) => {
  console.log('delete user plate')
  return httpClient.run.delete(`${END_POINT}/vehiclePlate`, {
    params: {
      id,
    }
  })
};
// stara utoryzacja
export const addGuestPlate = (plateNumber, userId, securityToken) => {
  console.log('post user plate')
  return httpClient.run.post(`${END_POINT}/vehiclePlate`, {}, {
    params: {
      plateNumber,
      userId,
      securityToken,
    }
  })
};


export const deleteGuestPlate = (plateNumber, userId, securityToken) => {
  console.log('delete user plate')
  return httpClient.run.delete(`${END_POINT}/vehiclePlate`,{
    params: {
      plateNumber,
      userId,
      securityToken,
    }
  })
};


