import * as Sentry from '@sentry/vue';
import VuexPersistence from 'vuex-persist';
import { createStore } from 'vuex';
import auth from './auth';
import parking from './parking';
import companies from './companies';
import admin from './admin';
import absence from './absence';

let storage;
try {
  storage = window.localStorage;
} catch (error) {
  console.log('localstorage not avaliable');
  Sentry.captureException(error);
}
const vuexLocal = new VuexPersistence({
  storage,
  reducer: (state) => ({
    parking: {
      currentParking: state.parking.currentParking,
    },
    auth: {
      tokens: {
        API: state.auth.tokens.API,
        grantedBySpaceosToken: state.auth.tokens.grantedBySpaceosToken,
      },
      user: state.auth.user,
    },

    tokenAPI: state.auth.tokens.API,
  }),
});

// Create a new store instance.
const store = createStore({
  state() {
    return {
      loading: false,
      routerLoading: false,
      parkingList: [
        {
          id: 1,
          title: 'Equator II',
          address1: 'Al. Jerozolimskie 96',
          address2: '00-807 Warszawa',
          image: 'equator-ii.jpg',
        },
        {
          id: 2,
          title: 'Equator I',
          address1: 'Al. Jerozolimskie 94',
          address2: '00-807 Warszawa',
          image: 'equator-i.jpg',
        },
        {
          id: 3,
          title: 'Warsaw Financial Center',
          address1: 'Ul. Emilii Plater 53',
          address2: '00-113 Warszawa',
          image: 'equator-i.jpgs',
        },
      ],
    };
  },
  mutations: {
    setLoading(state, payload) {
      state.loading = payload;
    },
    setRouterLoading(state, payload) {
      state.routerLoading = payload;
    },
  },
  getters: {
    getParkingList(state) {
      return state.parkingList;
    },
    getLoading(state) {
      return state.loading;
    },
    getRouterLoading(state) {
      return state.routerLoading;
    },
    getCompanies(state) {
      return state.companies;
    },
  },
  actions: {
    notify(context, { title, msg }) {
      alert(`${title} \n${msg || ''}`);
    },
  },
  plugins: [vuexLocal.plugin],
  modules: {
    auth,
    parking,
    companies,
    admin,
    absence,
  },
});

export default store;
