import { createRouter, createWebHistory } from 'vue-router';

import authGuard from './guards/authGuard';
import roleGuard from './guards/roleGuard';
import store from '@/store';

import ParkMeWrapper from '@/components/ui/layout/ParkMeWrapper';
import MobileBox from '@/components/ui/layout/MobileBox';
import home from '@/pages/home';
import login from '@/pages/auth/login';
import qr from '@/pages/user/qr';
import select from '@/pages/parking/select';
import reserve from '@/pages/parking/reserve';
import vehicles from '@/pages/user/vehicles';
import reservations from '@/pages/user/reservations';
import absence from '@/pages/user/absence';
import EmptyRoute from '@/components/ui/layout/EmptyRoute';
import company from '@/pages/company';
import companyUsers from '@/pages/company/users';
import repeatableReservations from '@/pages/company/repeatable-reservations';
import page404 from '@/pages/404';
import AdminLayout from '@/components/ui/layout/AdminLayout';
import floors from '@/pages/admin/parking-floors';
import adminUsers from '@/pages/admin/users';
import invitation from '@/pages/invitation';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      component: ParkMeWrapper,
      children: [
        {
          path: '',
          component: MobileBox,
          children: [
            {
              path: '',
              component: home,
              meta: {
                requiresAuth: true,
              },
            },
            {
              path: 'auth/login',
              component: login,
              meta: {
                requiresAuth: false,
              },
            },
            {
              path: 'user/qr-code',
              component: qr,
              meta: {
                requiresAuth: true,
              },
            },
            {
              path: 'parking/select',
              component: select,
              meta: {
                requiresAuth: true,
              },
            },
            {
              path: 'parking/reserve',
              component: reserve,
              meta: {
                requiresAuth: true,
              },
            },
            {
              path: 'user/vehicles/',
              component: vehicles,
              meta: {
                requiresAuth: true,
              },
            },
            {
              path: 'user/reservations/',
              component: reservations,
              meta: {
                requiresAuth: true,
              },
            },
            {
              path: 'user/absence/',
              component: absence,
              meta: {
                requiresAuth: true,
              },
            },
            {
              path: 'company/',
              component: EmptyRoute,
              meta: {
                requiresAuth: true,
                requiresRole: ['superadmin', 'parking_administration', 'company_admin'],
              },
              children: [
                {
                  path: '',
                  component: company,
                  meta: {
                    requiresAuth: true,
                    requiresRole: ['superadmin', 'parking_administration', 'company_admin'],
                  },
                },
                {
                  path: 'users',
                  component: companyUsers,
                  meta: {
                    requiresAuth: true,
                    requiresRole: ['superadmin', 'parking_administration', 'company_admin'],
                  },
                },
                {
                  path: 'repeatable-reservations',
                  component: repeatableReservations,
                  meta: {
                    requiresAuth: true,
                    requiresRole: ['superadmin', 'parking_administration', 'company_admin'],
                  },
                },
              ],
            },
            {
              path: ':pathmatch(.*)*',
              component: page404,
              meta: {
                requiresAuth: true,
              },
            },
          ],
        },
      ],
    },

    {
      path: '/admin',
      redirect: '/admin/parking',
      component: AdminLayout,
      children: [
        {
          path: 'parking',
          component: floors,
          meta: {
            requiresAuth: true,
            requiresRole: ['superadmin', 'parking_administration'],
          },
        },
        {
          path: 'users',
          component: adminUsers,
          meta: {
            requiresAuth: true,
            requiresRole: ['superadmin', 'parking_administration'],
          },
        },
      ],
    },

    {
      path: '/invitation/:userId/:secret/:reservationId',
      component: invitation,
      meta: {
        doNotRedirectOnError: true,
      },
    },
  ],
});

router.beforeEach(authGuard);
router.beforeEach(roleGuard);
router.beforeEach((to, from, next) => {
  store.commit('setRouterLoading', true);
  next();
});
router.afterEach(() => {
  store.commit('setRouterLoading', false);
});
export default router;
