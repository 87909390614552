import {
  getReservationsByDay,
  getReservationsByUser,
  getRepeatableForCompany,
} from '@/utils/api/parking_slot_reservation';

/* 
Structure:
    reservationsByDayAndSlot: {
      *day*: {
        *slot_id*: {
          ...reservations
        }
      }
    }
    reservationsByDayLoaded: {
      * day *: true / false
    }
 */

export default {
  state() {
    return {
      reservationsByDayAndSlot: {},
      reservationsByDayLoaded: {},
      myReservations: [],
      myReservationsLoaded: false,
      companyRepeatableReservations: [],
      companyRepeatableReservationsLoaded: false,
    };
  },
  mutations: {
    setReservationsByDay: (state, { day, reservations }) => {
      state.reservationsByDayAndSlot[day] = reservations;
      state.reservationsByDayLoaded[day] = true;
    },
    setMyReservations: (state, reservations) => {
      state.myReservations = reservations;
      state.myReservationsLoaded = true;
    },
    setCompanyRepeatableReservations: (state, reservations) => {
      state.companyRepeatableReservations = reservations;
      state.companyRepeatableReservationsLoaded = true;
    },
  },
  getters: {
    getMyReservations: (state) => state.myReservations,
    getMyReservationsLoaded: (state) => state.myReservationsLoaded,
    getCompanyRepeatableReservations: (state) => state.companyRepeatableReservations,
    getCompanyRepeatableReservationsLoaded: (state) => state.companyRepeatableReservationsLoaded,
    getReservationsByDayAndSlot: (state) => (day, slot) =>
      (state.reservationsByDayAndSlot[day] && state.reservationsByDayAndSlot[day][slot]) || [],
    getReservationsByDayLoaded: (state) => (day) => state.reservationsByDayLoaded[day] || false,
  },
  actions: {
    async fetchReservationsByDay({ commit }, day) {
      const response = await getReservationsByDay(day);
      /* eslint-disable no-param-reassign */
      const bySlots = response.data.reduce((accumulator, res) => {
        if (!accumulator[res.parkingSlotId]) accumulator[res.parkingSlotId] = [];
        accumulator[res.parkingSlotId].push(res);
        return accumulator;
      }, {});
      /* eslint-disable no-param-reassign */
      commit('setReservationsByDay', {
        day,
        reservations: bySlots,
      });
      return bySlots;
    },
    async fetchMyReservations({ commit, rootGetters }) {
      const userId = rootGetters['auth/user/getUser'].id;

      const response = await getReservationsByUser(userId);
      commit('setMyReservations', response.data);
      return response.data;
    },
    async fetchCompanyRepeatableReservations({ commit, rootGetters }) {
      const userId = rootGetters['auth/user/getUser'].spaceosCompany.id;

      const response = await getRepeatableForCompany(userId);
      commit('setCompanyRepeatableReservations', response.data);
      return response.data;
    },
  },
  namespaced: true,
};
