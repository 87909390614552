import { getCompanies } from '@/utils/api/spaceos_companies';

export default {
  state() {
    return {
      companies: [],
      loaded: false
    };
  },
  mutations: {
    setCompanies: (state, companies) => {
      state.companies = companies;
      state.loaded = true;
    }
  },
  getters: {
    getLoaded: (state) => state.loaded,
    getCompanies: (state) => state.companies
  },
  actions: {
    async fetchCompanies({ commit }) {
      const response = await getCompanies();
      commit('setCompanies', response.data);
      return response.data;
    }
  },
  namespaced: true
};
