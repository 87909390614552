<template>
  <div class="is-overlay spin-loader is-flex is-justify-content-center is-align-items-center">
    <button class="button is-loading is-inverted is-outlined is-grey"></button>
  </div>
</template>
<style lang="scss" scoped>
@import '@/assets/variables.scss';
.spin-loader {
  &:before {
    opacity: 0.4;
    content: '';
    @extend .has-background-primary;
    @extend .is-overlay;
    z-index: 200;
  }
  button {
    pointer-events: none;
    border: none;
    transform: scale(2.5);
  }
}
</style>
