import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faUserTie,
  faCheckCircle,
  faTrashAlt,
  faChevronLeft,
  faParking,
  faCheck,
  faCalendarCheck,
  faRoad,
  faAngleDoubleDown,
  faSignOutAlt,
  faSignInAlt,
  faTimes,
  faTimesCircle,
  faClock,
  faUserPlus,
  faPowerOff,
  faMapMarkerAlt,
  faCar,
  faInfoCircle,
  faLock,
  faLockOpen,
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faHistory,
  faUser,
  faUsers,
  faSearchMinus,
  faSearchPlus,
  faCircle,
} from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(
  faCircle,
  faUserTie,
  faCheckCircle,
  faTrashAlt,
  faChevronLeft,
  faParking,
  faCheck,
  faCalendarCheck,
  faRoad,
  faAngleDoubleDown,
  faSignOutAlt,
  faSignInAlt,
  faTimes,
  faTimesCircle,
  faClock,
  faUserPlus,
  faPowerOff,
  faMapMarkerAlt,
  faCar,
  faInfoCircle,
  faLock,
  faLockOpen,
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faHistory,
  faUser,
  faUsers,
  faSearchMinus,
  faSearchPlus
);

export default FontAwesomeIcon;
