<template>
  <div class="select is-small">
    <select v-on:change="updateUserId">
      <option v-if="fetchedUsers" default :value="null" selected>{{ this.user.email }}</option>
      <template v-for="usr in users" :key="usr.id" :value="usr.email">
        <option v-if="usr.role !== 'guest' && usr.id !== user.id" :value="usr.id">
          {{ usr.email }}
        </option>
      </template>
    </select>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import userMixin from '@/mixins/userMixin';

export default {
  mixins: [userMixin],
  data() {
    return {
      fetchedUsers: false,
    };
  },

  computed: {
    ...mapGetters({
      users: 'admin/getUsers',
    }),
  },
  methods: {
    ...mapActions({
      fetchUsers: 'admin/fetchUsersIfEmpty',
    }),
    updateUserId(event) {
      this.$emit('update:modelValue', event.target.value);
    },
  },

  mounted() {
    this.fetchUsers();
    this.fetchedUsers = true;
  },
};
</script>
import
<style></style>
