import lots from './lots';
import floors from './floors';
import slots from './slots';
import reservations from './reservations';

export default {
  state: {
    currentParking: {}
  },
  mutations: {
    setCurrentParking(state, payload) {
      state.currentParking = payload;
    }
  },
  getters: {
    getCurrentParking(state) {
      return state.currentParking;
    }
  },
  actions: {
    async setParkingByLocationId({ commit, dispatch }, locationId) {
      const parkingList = await dispatch('lots/fetchLots');
      console.log('parking id', typeof locationId);
      console.log('parkings', parkingList);
      const current = parkingList.find((e) => e.spaceos_location_id === parseInt(locationId, 10));
      if (!current) {
        dispatch(
          'notify',
          {
            title: 'Nie znaleziono parkingu o podanym ID.',
            msg: 'wybierz parking ręcznie'
          },
          {
            root: true
          }
        );
        commit('setCurrentParking', {});
        return false;
      }
      commit('setCurrentParking', current);
      return true;
    }
  },
  modules: {
    lots,
    floors,
    slots,
    reservations
  },
  namespaced: true
};
