import { getFloorList, getFloorDetails } from '@/utils/api/parking_floor';

/* 
Structure:
    floorList: {
      *lot_id*: [
        {
          ...floor details
        },
      ]
    }
    floorListLoading: {
      *lot_id*: true / false
    }

    floorDeails: {
      *floor_id*: {
        ...floor details,
        "parkingLayout2d": [
          ..parking spots
        ]
      }, 
    }

    floorDeailsLoading: {
      * floor_id *: true / false,
    }
 */

export default {
  state() {
    return {
      floorList: {},
      floorListLoading: {},
      floorDetails: {},
      floorDetailsLoading: {}
    };
  },
  mutations: {
    setFloorList: (state, { lotId, floors }) => {
      state.floorList[lotId] = floors;
    },
    setFloorListLoading: (state, { lotId, loading }) => {
      state.floorListLoading[lotId] = loading;
    },
    setFloorDetails: (state, { floorId, floor }) => {
      state.floorDetails[floorId] = floor;
    },
    setFloorDetailsLoading: (state, { floorId, loading }) => {
      state.floorDetailsLoading[floorId] = loading;
    }
  },
  getters: {
    // We use method style getters https://vuex.vuejs.org/guide/getters.html#method-style-access
    getFloorListLoading: (state) => (lotId) => state.floorListLoading[lotId] || false,
    getFloorDetailsLoading: (state) => (floorId) => state.floorDetailsLoading[floorId] || false,
    getFloorList: (state) => (lotId) => state.floorList[lotId] || {},
    getFloorDetails: (state) => (floorId) => state.floorDetails[floorId] || {},
    getParkingSlot:
      (state) =>
      ({ floorId, x, y }) =>
        (state.floorDetails[floorId] && state.floorDetails[floorId].parkingLayout2d[x][y]) || {}
  },
  actions: {
    async fetchFloorList({ commit, state }, lotId) {
      if (state.floorListLoading[lotId] === true) {
        return 'loading';
      }
      commit('setFloorListLoading', {
        lotId,
        loading: true
      });
      const response = await getFloorList(lotId);
      commit('setFloorList', {
        lotId,
        floors: response.data
      });
      commit('setFloorListLoading', {
        lotId,
        loading: false
      });
      return true;
    },
    async fetchFloorDetails({ commit, state }, floorId) {
      if (state.floorDetailsLoading[floorId] === true) {
        return 'loading';
      }
      commit('setFloorDetailsLoading', {
        floorId,
        loading: true
      });
      const response = await getFloorDetails(floorId);
      commit('setFloorDetails', {
        floorId,
        floor: response.data[0]
      });
      commit('setFloorDetailsLoading', {
        floorId,
        loading: false
      });
      return true;
    }
  },
  namespaced: true
};
