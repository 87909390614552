import axios from 'axios';

import authInterceptor from './interceptors/authInterceptor';
import errorInterceptor from './interceptors/errorInterceptor';
import responseInterceptor from './interceptors/responseInterceptor';

const httpClient = {
  run: null,
};

function init(data) {
  httpClient.run = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    timeout: Number(process.env.VUE_APP_AXIOS_TIMEOUT) || 10 * 1000,
    headers: {
      'Content-Type': 'application/json',
    },
  });
  httpClient.run.interceptors.request.use(authInterceptor(data));
  httpClient.run.interceptors.response.use(responseInterceptor(data), errorInterceptor(data));
}

export { httpClient, init };
