<template>
  <div class="is-flex is-flex-direction-column is-justify-content-stretch h-full is-clipped">
    <div>
      <h2 class="title is-5 my-4">
        {{ 'Użytkownicy' }}
        <span @click="openModal = !openModal" class="icon ml-1 has-text-dark">
          <FaIcon icon="info-circle" />
        </span>
      </h2>
    </div>
    <ScrollBox class="is-flex-grow-1">
      <Skeleton v-if="!usersLoaded" :elements="['icon-text']"> </Skeleton>
      <template v-else>
        <div class="block"></div>
        <template v-for="(user, idx) in sortedUsers" :key="idx">
          <div v-if="user.role !== 'guest'" class="box">
            <div class="columns is-mobile is-vcentered">
              <div class="column has-text-left">
                <div>
                  <strong>
                    {{ user.name }}
                  </strong>
                </div>
                <div>
                  <small>{{ user.email }}</small>
                </div>
                <div v-if="user.role !== 'user'">
                  <span class="tag is-primary is-small">
                    {{ user.role }}
                  </span>
                </div>
              </div>
              <div class="column has-text-right is-narrow">
                <span
                  v-if="user.allowEntryWithoutReservation !== true"
                  @click="allowEntry(user.id)"
                  class="icon has-text-grey-light"
                >
                  <FaIcon icon="lock" />
                </span>
                <span v-else @click="disallowEntry(user.id)" class="icon has-text-primary">
                  <FaIcon icon="lock-open" />
                </span>
              </div>
            </div>
          </div>
        </template>
      </template>
    </ScrollBox>
    <div class="columns is-half is-mobile">
      <div class="column has-text-left">
        <GoBack />
      </div>
    </div>
  </div>
  <SpinLoader v-if="loading" />
  <Modal v-model="openModal">
    <p class="box is-shadowless">
      <span class="icon has-text-primary">
        <FaIcon icon="lock-open" />
      </span>
      {{ $t('users.info') }}
    </p>
  </Modal>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import Skeleton from '@/components/ui/layout/Placeholders/Skeleton';
import ScrollBox from '@/components/ui/layout/ScrollBox';
import Modal from '@/components/ui/layout/Modal';
import GoBack from '@/components/ui/GoBack';
import SpinLoader from '@/components/ui/layout/Placeholders/SpinLoader';
import { setAllowEntryWithoutReservation } from '@/utils/api/user';

export default {
  components: {
    Modal,
    GoBack,
    ScrollBox,
    Skeleton,
    SpinLoader,
  },
  data() {
    return {
      openModal: false,
      loading: false,
    };
  },

  computed: {
    ...mapGetters({
      usersLoaded: 'admin/getLoaded',
      users: 'admin/getUsers',
      companies: 'companies/getCompanies',
    }),

    sortedUsers() {
      return this.users.slice().sort((a, b) => `${a.name}`.localeCompare(b.name));
    },
  },
  methods: {
    ...mapActions({
      fetchUsers: 'admin/fetchUsers',
      fetchCompanies: 'companies/fetchCompanies',
    }),
    async allowEntry(userId) {
      this.loading = true;
      try {
        await setAllowEntryWithoutReservation(userId, true);
      } catch (err) {
        console.log(err);
      }
      await this.fetchUsers();
      this.loading = false;
    },
    async disallowEntry(userId) {
      this.loading = true;
      try {
        await setAllowEntryWithoutReservation(userId, false);
      } catch (err) {
        console.log(err);
      }
      await this.fetchUsers();
      this.loading = false;
    },
  },

  mounted() {
    this.fetchUsers();
    this.fetchCompanies();
  },
};
</script>
