<template>
  <div>
    <div class="is-mobile mb-0">
      <h1 class="title is-5">
        {{ user.id === res.userId ? 'Twoja ' : '' }}{{ $t('reservations.reservation') }}
      </h1>
      <h1 class="subtitle is-5 mb-3">#{{ res.id }}</h1>
    </div>
    <ReservationsBox :res="res" :no-img="true" />
    <div class="columns is-mobile is-1 is-variable is-multiline">
      <div class="column has-text-left">
        <button
          class="button w-full is-primary is-outlined has-background-light"
          @click="this.$emit('close')"
        >
          <span>Gotowe</span>
          <!-- <span class="icon">
            <FaIcon icon="check"/>
          </span> -->
        </button>
      </div>
      <div class="column has-text-right" v-if="res.status !== 'reserved_cancelled'">
        <button class="button w-full is-primary has-text-left" @click="this.$emit('addGuest')">
          <span>
            {{ $t(res.guestId ? 'reservations.editGuest' : 'reservations.addGuest') }}
          </span>
          <!-- <span class="icon">
            <FaIcon icon="user-plus"/>
          </span> -->
        </button>
      </div>
      <div
        class="column is-full has-text-center pt-0"
        v-if="res.status === 'reserved' || res.repeatEvery"
      >
        <button
          class="button w-full is-danger has-text-left is-outlined"
          @click="del"
          :class="{ 'is-loading': loading }"
        >
          <span>
            {{ $t('reservations.delete') }}
          </span>
          <span class="icon">
            <FaIcon icon="times" />
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ReservationsBox from '@/components/ReservationsBox';
import { deleteReservation } from '@/utils/api/parking_slot_reservation';
import userMixin from '@/mixins/userMixin';

export default {
  mixins: [userMixin],
  data() {
    return {
      loading: false,
    };
  },
  components: {
    ReservationsBox,
  },
  computed: {
    ...mapGetters({ parking: 'parking/getCurrentParking' }),
  },
  props: {
    res: Object,
    guest: {
      name: String,
      licencePlate: String,
    },
  },
  methods: {
    async del() {
      this.loading = true;
      // eslint-disable-next-line no-restricted-globals
      const confirmed = confirm('Czy na pewno chcesz usunąć rezerwację?');
      if (confirmed) {
        await deleteReservation(this.res.id);
        this.$emit('reservationDeleted');
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.parking-image {
  object-fit: cover;
  object-position: bottom;

  &.center {
    object-position: center;
  }
}
</style>
