<template>
  <div class="is-mobile">
    <span class="icon is-size-1 has-text-left mb-6">
      <FaIcon icon="user-plus" />
    </span>
    <h2 class="title is-6">{{ $t('guest.addedTitle') }}</h2>
    <h3 class="subtitle is-6">{{ $t('guest.addedSubtitle') }}</h3>
    <div v-for="(item, key) in guest" :key="key">
      <p class="help has-text-left is-grey">{{ key }}</p>
      <div class="box is-shadowless has-text-left label p-2 block">
        {{ item }}
      </div>
    </div>
    <div>
      <button
        class="button is-primary is-outlined has-background-light block mt-5"
        @click="this.$emit('close')"
      >
        <span> {{ 'reservations.ready' }} </span>
        <span class="icon">
          <FaIcon icon="check" />
        </span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    guest: Object,
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/variables.scss';
.modal-content {
  max-width: calc($mobile-box-width - 50px);
}
.car {
  &:hover {
    @extend .has-background-primary-light;
  }
  &.current {
    border: 1px solid $primary;
  }
}
</style>
