<template>
  <div class="h-screen is-flex is-flex-direction-column has-background-white">
    <nav class="navbar is-primary has-shadow" role="navigation" aria-label="main navigation">
      <div class="navbar-brand">
        <router-link class="navbar-item" to="/">
          <img src="@/assets/img/parkme_logo_white.svg" width="112" />
          <span class="ml-2"> {{ $t('admin.adminPanel') }}</span>
        </router-link>
        <router-link class="navbar-item" to="/admin/users">
          <span class="ml-2"> {{ $t('admin.users') }} </span>
        </router-link>
        <router-link class="navbar-item" to="/admin/parking">
          <span class="ml-2"> {{ $t('admin.parking') }}</span>
        </router-link>

        <button
          class="navbar-burger pt-1"
          :class="{ 'is-active': showMenu }"
          @click="toggleMenu"
          aria-label="menu"
          aria-expanded="false"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </button>
      </div>

      <div class="navbar-menu" :class="{ 'is-active': showMenu }">
        <!-- <div class="navbar-start">
          <div class="navbar-item has-dropdown is-hoverable" :class="{ 'is-active': show }">
            <router-link to="/admin" class="navbar-link"> Parking </router-link>
            <div class="navbar-dropdown">
              <router-link to="/admin" class="navbar-item"> Zarządzanie miejscami </router-link>
            </div>
          </div>
        </div> -->
        <div class="navbar-end">
          <div class="navbar-item">
            <current-parking class="desktop-white" />
          </div>
        </div>
        <button
          class="button is-primary navbar-icon pt-4 pr-4 is-centered"
          @click="logoutUserFromApp()"
          v-if="tokenGratend === null"
        >
          <div class="icon is-primary">
            <FaIcon icon="power-off" />
          </div>
        </button>
      </div>
    </nav>
    <div class="container is-widescreen is-clipped w-full">
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import CurrentParking from '@/components/CurrentParking';

export default {
  data() {
    return {
      showMenu: false,
    };
  },
  computed: {
    ...mapGetters({
      tokenGratend: 'auth/getGrantedBySpaceosToken',
    }),
  },
  components: {
    CurrentParking,
  },
  methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    ...mapMutations({
      clearTokens: 'auth/clearTokens',
    }),
    logoutUserFromApp() {
      // eslint-disable-next-line no-restricted-globals
      const validate = confirm('Are you sure you want to logout?');
      if (validate) {
        this.clearTokens();
        this.$router.push({ path: '/auth/login' });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';
.desktop-white {
  @include desktop {
    color: #fff;
  }
}
</style>
