import { httpClient } from './httpClient';

const END_POINT = '/user_days_of_absence';

export const getUserDaysOfAbsence = (startDate, endDate) =>
  httpClient.run.get(`${END_POINT}`, {
    params: {
      startDate,
      endDate,
    },
  });

export const addUserDaysOfAbsence = (startDate, endDate, overrideUserId) =>
  httpClient.run.post(
    `${END_POINT}`,
    {},
    {
      params: {
        startDate,
        endDate,
        overrideUserId,
      },
    }
  );

export const deleteUserDaysOfAbsence = (id) =>
  httpClient.run.delete(`${END_POINT}`, {
    params: {
      id,
    },
  });
