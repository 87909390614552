import { getMe } from './me';

import { httpClient } from '../httpClient';

const END_POINT = '/auth';

const loginUsingSpaceosToken = (token) =>
  httpClient.run.post(`${END_POINT}/loginUsingSpaceosToken`, {
    spaceosUniqueAppId: process.env.VUE_APP_API_SPACEOS_ID,
    spaceosToken: token,
  });

const loginAsGuest = (id, securityToken) =>
  httpClient.run.post(
    `${END_POINT}/loginAsGuest`,
    {},
    {
      params: {
        id,
        securityToken,
      },
      meta: {
        noAuthError: true,
        noAuth: true,
      },
    }
  );

export { getMe, loginUsingSpaceosToken, loginAsGuest };
