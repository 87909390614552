<template>
  <div class="is-flex is-flex-direction-column is-justify-content-stretch h-full is-clipped">
    <h2 class="title is-5 has-text-center mt-4">{{ $t('selectListItems.recurentRes') }}:</h2>

    <scroll-box class="p-0 is-flex-grow-1">
      <Skeleton v-if="!isLoaded || !canRender" :elements="['card-img', 'lg-text']"> </Skeleton>

      <template v-else>
        <ReservationsBox
          heigth="50"
          v-for="(reservation, idx) in reservations"
          :res="reservation"
          :key="idx"
          @click="edit(reservation)"
        ></ReservationsBox>
      </template>
    </scroll-box>
    <small v-if="isLoaded && !reservations.length" class="is-size-6">{{
      $t('reservations.noReservations')
    }}</small>
    <reservation-modal ref="reservationModal" @update="fetch" />

    <div class="columns is-mobile">
      <div class="column is-half has-text-right">
        <go-back />
      </div>
      <div class="column is-half has-text-right">
        <router-link class="button is-primary has-text-right" to="/parking/reserve">
          Dodaj
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import GoBack from '@/components/ui/GoBack';
import ScrollBox from '@/components/ui/layout/ScrollBox';
import ReservationsBox from '@/components/ReservationsBox';
import Skeleton from '@/components/ui/layout/Placeholders/Skeleton';
import ReservationModal from '@/components/ReservationModal';

export default {
  data() {
    return {
      canRender: false,
    };
  },

  computed: {
    ...mapGetters({
      reservations: 'parking/reservations/getCompanyRepeatableReservations',
      isLoaded: 'parking/reservations/getCompanyRepeatableReservationsLoaded',
    }),
  },
  methods: {
    ...mapActions({
      fetch: 'parking/reservations/fetchCompanyRepeatableReservations',
      fetchSlots: 'parking/slots/fetchSlotsIfEmpty',
      fetchLots: 'parking/lots/fetchLotsIfEmpty',
    }),
    edit(res) {
      this.$refs.reservationModal.edit(res);
    },
  },

  async mounted() {
    this.fetch();
    await this.fetchSlots();
    await this.fetchLots();
    this.canRender = true;
  },
  components: {
    GoBack,
    ScrollBox,
    ReservationsBox,
    Skeleton,
    ReservationModal,
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/variables.scss';
.parking {
  &:hover {
    @extend .has-background-primary-light;
  }
  &.current {
    border: 1px solid $primary;
  }
}
</style>
