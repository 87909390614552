<template>
  <div
    class="is-relative map-wrapper"
    :style="getStyle"
    :class="{ 'has-background-primary-light': viewportRender }"
  >
    <navigation-path
      ref="navigationPath"
      class="is-overlay is-unclickable"
      :block-size="blockSize"
      :floor-details="floorDetails"
      :style="{
        height: `${mapHeight}px`,
        width: `${mapWidth}px`,
      }"
    />
    <div
      class="is-flex"
      :style="
        viewportRender
          ? {
              position: 'absolute',
              left: viewportCalculated.left * this.blockSize + 'px',
              top: viewportCalculated.top * this.blockSize + 'px',
            }
          : {}
      "
    >
      <div
        class="slot-col is-flex is-flex-direction-column is-flex-shrink-0 is-flex-grow-0"
        v-for="(col, colIdx) in floorDetails.parkingLayout2d &&
        floorDetails.parkingLayout2d.slice(viewportCalculated.left, viewportCalculated.right)"
        :key="'slot-col-' + colIdx"
      >
        <template
          v-for="(slot, slotIdx) in col.slice(viewportCalculated.top, viewportCalculated.bottom)"
          :key="'slot-' + slotIdx"
        >
          <div
            class="button slot p-0 is-flex-grow-0 is-flex-shrink-0"
            :class="[
              slot.spaceType,
              {
                'is-warning': isSelected(slot),
                selected: isSelected(slot),
                'is-gray-light': slot.spaceType === 'building',
                'is-primary is-outlined': slot.spaceType === 'parking_slot' && !isSelected(slot),
              },
            ]"
            :style="{
              height: `${blockSize}px`,
              width: `${blockSize}px`,
            }"
            @click="updateSelectedSlot(slot)"
          >
            <div
              v-if="slot.spaceType === 'parking_slot'"
              class="arrow"
              :class="slot.parkingSlot.direction"
            ></div>
            <small v-if="slot.spaceType === 'parking_slot'"
              >{{ slot.parkingSlot && slot.parkingSlot.parkingSlotNumber }}
            </small>
            <span class="icon is-small has-text-white" v-if="slot.spaceType === 'road'">
              <FaIcon icon="road" />
            </span>
            <span class="icon is-small has-text-white" v-if="slot.spaceType === 'entrance'">
              <FaIcon icon="angle-double-down" />
            </span>
            <span class="icon is-small has-text-white" v-if="slot.spaceType === 'exit'">
              <FaIcon icon="sign-out-alt" />
            </span>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import NavigationPath from './NavigationPath';

export default {
  name: 'ParkingMap',
  data() {
    return {
      loading: false,
      slotSize: 40,
      showPath: false,
    };
  },
  components: {
    NavigationPath,
  },
  props: {
    selectedSlot: Object,
    floorId: Number,
    lotId: Number,
    blockSize: {
      type: Number,
      default: 40,
    },
    fitWidth: Number,
    scale: {
      type: Number,
      default: 1,
    },
    pathOnLoad: Boolean,
    viewportRender: Boolean,
    viewport: Object,
  },
  computed: {
    ...mapGetters({
      currentParking: 'parking/getCurrentParking',
      getFloorDetails: 'parking/floors/getFloorDetails',
    }),
    viewportCalculated() {
      if (this.viewportRender) {
        const preload = 0.5;
        const width = this.viewport.right - this.viewport.left;
        const height = this.viewport.bottom - this.viewport.top;
        return {
          left: Math.floor(Math.max(0, this.viewport.left - width * preload) / this.blockSize),
          right: Math.ceil((this.viewport.right + width * preload) / this.blockSize),
          top: Math.floor(Math.max(0, this.viewport.top - height * preload) / this.blockSize),
          bottom: Math.ceil((this.viewport.bottom + height * preload) / this.blockSize),
          width,
          height,
        };
      }
      return {
        left: 0,
        top: 0,
        right: this.floorDetails.xDimension,
        bottom: this.floorDetails.yDimension,
      };
    },
    floorDetails() {
      // return this.getFloorDetails(this.floorId);
      return this.getFloorDetails(this.floorId);
    },
    getStyle() {
      const scale = this.fitWidth ? this.fitWidth / this.mapWidth : this.scale;
      return {
        transform: `scale(${scale})`,
        'transform-origin': 'top center',
        width: `${this.scale * this.mapWidth}px`,
        height: `${this.scale * this.mapHeight}px`,
        'margin-bottom': `${(scale - 1) * this.mapHeight}px`,
      };
    },
    mapWidth() {
      return this.blockSize * this.floorDetails.xDimension;
    },
    mapHeight() {
      return this.blockSize * this.floorDetails.yDimension;
    },
  },
  methods: {
    ...mapActions({ fetchFloorDetails: 'parking/floors/fetchFloorDetails' }),
    calculatePath() {
      this.$nextTick(() => {
        this.$refs.navigationPath.showPath(this.selectedSlot);
      });
    },
    isSelected(slot) {
      return (
        this.selectedSlot && slot.x === this.selectedSlot[0] && slot.y === this.selectedSlot[1]
      );
    },
    fetch() {
      this.fetchFloorDetails(this.floorId);
    },
    updateSelectedSlot(slot) {
      const newSlot =
        this.selectedSlot && this.selectedSlot.x === slot.x && this.selectedSlot.y === slot.y
          ? null
          : [slot.x, slot.y];
      this.$emit('update:selectedSlot', newSlot);
    },
  },
  mounted() {
    this.fetch();
  },
  watch: {
    floorDetails(val) {
      if (this.pathOnLoad && val) {
        this.calculatePath();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';
.slot {
  &.road {
    background-color: transparent;
    border-radius: 0;
    border: none;
    opacity: 0;
    &:hover,
    &.selected {
      background-color: $grey;
      opacity: 1;
    }
  }
  &.building {
    background-color: $grey-lighter;
    border-radius: 0;
    border: none;
    &:hover,
    &.selected {
      background-color: $grey-light;
    }
  }
  &.entrance,
  &.exit {
    // background: linear-gradient(182deg, $primary, $white);
    background: $primary;
    &.exit {
      // background: linear-gradient(182deg, $white, $primary);
      background: $primary;
    }
    border: none;
    border-radius: 0;
    &.selected {
      background: $primary;
    }
  }
  .arrow {
    @extend .is-overlay;
    background-color: $primary;
    clip-path: polygon(0 20%, 0 80%, 30% 50%);
    opacity: 0.2;
    &.up {
      transform: rotate(270deg);
    }
    &.left {
      transform: rotate(180deg);
    }
    &.down {
      transform: rotate(90deg);
    }
  }
  &:hover {
    .arrow {
      background-color: $white;
    }
  }
}
</style>
