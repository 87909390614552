<template>
  <div v-if="parkingSlot">
    <p v-if="msg" class="help is-danger">{{ msg }}</p>
    <div class="box">
      <div class="tags has-addons">
        <span class="tag is-primary is-light">X: {{ parkingSlot.x }}</span
        ><span class="tag is-primary is-light">Y: {{ parkingSlot.y }}</span>
      </div>
      <div class="tags has-addons are-small">
        <span class="tag">{{ $t('slotDetails.type') }}</span
        ><span class="tag is-primary">{{ parkingSlot.spaceType }}</span>
      </div>
    </div>
    <div class="mb-5">
      <div v-if="parkingSlot.spaceType !== 'parking_slot'">
        <span class="label is-small">{{ $t('slotDetails.createPlace') }}</span>
        <div class="field has-addons">
          <div class="control">
            <input
              v-model="slotNumber"
              class="input is-small"
              type="text"
              placeholder="Number miejsca"
              :disabled="loading"
            />
          </div>
          <div class="control">
            <button
              class="button is-primary is-small"
              :class="{ 'is-loading': loading }"
              @click="createSpot"
              :disabled="loading"
            >
              {{ $t('slotDetails.create') }}
            </button>
          </div>
        </div>
        <span class="label is-small"> {{ $t('slotDetails.changeTypeOfSpace') }}</span>
        <div class="field has-addons">
          <div class="select is-normal" :class="{ 'is-loading': loading }">
            <select :value="parkingSlot.spaceType" @change="changeType">
              <option value="road">{{ $t('slotDetails.road') }}</option>
              <option value="building">{{ $t('slotDetails.building') }}</option>
              <option value="entrance">{{ $t('slotDetails.entrance') }}</option>
              <option value="exit">{{ $t('slotDetails.exit') }}</option>
            </select>
          </div>
        </div>
      </div>
      <div v-else>
        <span class="label is-small">{{ $t('admin.assignCompany') }} </span>
        <div class="select" :class="{ 'is-loading': loading }">
          <select :value="-1" @change="assignToCompany">
            <option :value="-1">{{ $t('admin.choose') }}</option>
            <option
              v-for="(company, idx) in getCompanies"
              :key="idx"
              :value="company.id"
              :disabled="isAssignedToCompany(company.id)"
            >
              {{ company.spaceos_company_name }}
            </option>
          </select>
        </div>

        <div class="mt-3 mb-6 field is-grouped is-grouped-multiline">
          <div
            v-for="(company, id) in parkingSlot.parkingSlot.spaceosCompanies"
            :key="id"
            class="control"
          >
            <div class="tags has-addons">
              <span class="tag is-warning">{{ company.spaceos_company_name }}</span>
              <a class="tag is-delete" @click="deassignToCompany(company.id)"></a>
            </div>
          </div>
        </div>
        <span class="label is-small"> {{ $t('admin.changeDirection') }} </span>
        <div class="field has-addons">
          <div class="select is-normal" :class="{ 'is-loading': loading }">
            <select :value="parkingSlot.parkingSlot.direction" @change="changeDirection">
              <option value="left">left</option>
              <option value="right">right</option>
              <option value="up">up</option>
              <option value="down">down</option>
            </select>
          </div>
        </div>
        <span class="label is-small">{{ $t('slotDetails.delete') }}</span>
        <button
          class="button is-danger is-outlined is-small"
          :class="{ 'is-loading': loading }"
          :disabled="loading"
          @click="deleteSpot"
        >
          <span>{{ $t('slotDetails.delete') }}</span>
          <span class="icon is-small">
            <FaIcon icon="times" />
          </span>
        </button>
      </div>
      <button class="button is-success is-small mt-5" @click="$emit('calculatePath')">
        {{ $t('slotDetails.showWay') }}
      </button>
    </div>
  </div>

  <div class="box menu">
    <TreeMenu :nodes="parkingSlot"></TreeMenu>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
  createParkingSlot,
  deleteParkingSlot,
  assignToCompany,
  deassignToCompany,
} from '@/utils/api/parking_slot';
import { createParkingEmptyParkingSlot } from '@/utils/api/parking_empty_slot';
// eslint-disable-next-line import/extensions
import TreeMenu from '@/components/TreeMenu';

export default {
  data() {
    return {
      slotNumber: null,
      loading: false,
      msg: null,
      selectedSpot: null,
      showElement: true,
    };
  },
  emits: ['update', 'calculatePath'],
  components: {
    TreeMenu,
  },
  props: {
    slotPosition: Array,
    floorId: Number,
  },

  computed: {
    ...mapGetters({
      getParkingSlot: 'parking/floors/getParkingSlot',
      getCompanies: 'companies/getCompanies',
    }),
    parkingSlot() {
      return this.slotPosition && this.slotPosition.length
        ? this.getParkingSlot({
            floorId: this.floorId,
            x: this.slotPosition[0],
            y: this.slotPosition[1],
          })
        : null;
    },
  },

  methods: {
    ...mapActions({ fetchCompanies: 'companies/fetchCompanies' }),
    isAssignedToCompany(id) {
      return this.parkingSlot.parkingSlot.spaceosCompanies.some((e) => e.id === id);
    },
    async assignToCompany(event) {
      if (event.target.value !== -1) {
        try {
          this.loading = true;
          const response = await assignToCompany(
            this.parkingSlot.parkingSlot.id,
            event.target.value
          );
          console.log(response);
          this.$emit('update');
          this.msg = null;
        } catch (err) {
          console.log(err);
          this.msg = err.message;
        }
        // eslint-disable-next-line no-param-reassign
        event.target.value = -1;
        this.loading = false;
      }
    },
    async deassignToCompany(id) {
      try {
        this.loading = true;
        const response = await deassignToCompany(this.parkingSlot.parkingSlot.id, id);
        console.log(response);
        this.$emit('update');
        this.msg = null;
      } catch (err) {
        console.log(err);
        this.msg = err.message;
      }
      this.loading = false;
    },
    async createSpot() {
      try {
        this.loading = true;
        const response = await createParkingSlot(
          this.parkingSlot.x,
          this.parkingSlot.y,
          this.floorId,
          this.slotNumber
        );
        console.log(response);
        this.$emit('update');
        this.msg = null;
        this.slotNumber = null;
      } catch (err) {
        console.log(err);
        this.msg = err.message;
      }
      this.loading = false;
    },
    async changeDirection(event) {
      try {
        this.loading = true;
        const response = await createParkingSlot(
          this.parkingSlot.x,
          this.parkingSlot.y,
          this.floorId,
          this.parkingSlot.parkingSlot.parkingSlotNumber,
          event.target.value
        );
        console.log(response);
        this.$emit('update');
        this.msg = null;
        this.slotNumber = null;
      } catch (err) {
        console.log(err);
        this.msg = err.message;
      }
      this.loading = false;
    },
    async deleteSpot() {
      try {
        this.loading = true;
        const response = await deleteParkingSlot(this.parkingSlot.parkingSlot.id);
        console.log(response);
        this.$emit('update');
        this.msg = null;
      } catch (err) {
        console.log(err);
        this.msg = err.message;
      }
      this.loading = false;
    },
    async changeType(event) {
      try {
        this.loading = true;
        const response = await createParkingEmptyParkingSlot(
          this.parkingSlot.x,
          this.parkingSlot.y,
          this.floorId,
          event.target.value
        );
        console.log(response);
        this.$emit('update');
        this.msg = null;
      } catch (err) {
        console.log(err);
        this.msg = err.message;
      }
      this.loading = false;
    },
  },

  mounted() {
    this.fetchCompanies();
  },
};
</script>
