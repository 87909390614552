import user from './user';
import { loginUsingSpaceosToken } from '@/utils/api/auth';

export default {
  state() {
    return {
      tokens: {
        grantedBySpaceosToken: null,
        API: null,
        guest: null,
      },
    };
  },
  mutations: {
    setGrantedBySpaceosToken: (state, token) => {
      state.tokens.grantedBySpaceosToken = token;
    },
    setTokenAPI: (state, token) => {
      state.tokens.API = token;
    },
    setGuestToken: (state, token) => {
      state.tokens.guest = token;
    },
    clearTokens: (state) => {
      state.tokens = {
        grantedBySpaceosToken: null,
        API: null,
      };
    },
  },
  getters: {
    getGrantedBySpaceosToken: (state) => state.tokens.grantedBySpaceosToken,
    getTokenAPI: (state) => state.tokens.API,
    getTokenGuest: (state) => state.tokens.guest,
    getTokens: (state) => state.tokens,
    getUser: (state) => state.user,
  },
  actions: {
    async setTokenAPI({ commit, dispatch }, token) {
      commit('setTokenAPI', token);
      commit('setGrantedBySpaceosToken', null);
      return dispatch('user/fetchUser');
    },
    async loginBySpaceosToken({ commit }, spaceosToken) {
      commit('setTokenAPI', null);
      commit('setGrantedBySpaceosToken', null);
      const response = await loginUsingSpaceosToken(spaceosToken);
      if (response.data.accessToken) {
        commit('setTokenAPI', response.data.accessToken);
        commit('setGrantedBySpaceosToken', spaceosToken);
        return true;
      }
      return false;
    },
  },
  modules: {
    user,
  },
  namespaced: true,
};
