<template>
  <div
    class="card parking block is-clickable"
    :style="res.status === 'reserved_cancelled' ? 'opacity: 0.5;' : ''"
  >
    <div>
      <div class="card-image" v-if="!noImg">
        <figure class="image is-3by1">
          <img
            :src="`/assets/img/parkings/${lot && lot.spaceos_location_id}.jpg`"
            onerror="this.src='/assets/img/parkings/garage.jpg'; this.classList+=' center';"
            class="parking-image"
          />
        </figure>
      </div>
      <div
        class="card-content p-2"
        :class="{ repeatable: res.repeatEvery, noimg: noImg && res.repeatEvery }"
      >
        <div>
          <div class="block">
            <div class="columns is-mobile mb-0 is-gapless is-vcentered">
              <div class="column has-text-left pb-0 is-narrow">
                <span class="icon is-justify-content-flex-start">
                  <FaIcon :icon="`${statusIcons[res.status]}`" />
                </span>
                <strong>{{ $t(`reservations.statuses.${res.status}`) }}</strong>
              </div>
              <div class="column has-text-right pb-0">
                <strong class="is-size-11">
                  {{ timeFormat(res.startDate) }}-{{ timeFormat(res.endDate) }}
                </strong>
                <span class="icon pb-3">
                  <FaIcon icon="clock" />
                </span>
              </div>
            </div>
            <div class="columns is-mobile mb-0 is-gapless is-vcentered">
              <div class="column has-text-left pb-0">
                <span> {{ $t('reservations.slotNumber') }}: </span>
                <strong>{{ slot && slot.parkingSlotNumber }}</strong>
              </div>
              <div class="column has-text-right pb-0">
                <template v-if="res.repeatEvery">
                  <strong class="">
                    <span v-if="res.repeatEvery === 'day'">
                      {{ $t(`reservations.repeat.everyday`) }}
                    </span>
                    <span v-else-if="res.repeatEvery === 'week'">
                      {{ $t(`reservations.repeat.every`) }}
                      {{ getWeekday(res.startDate) }}
                    </span>
                    <span v-else>
                      {{ $t(`reservations.repeat.${res.repeatEvery}`) }}
                    </span>
                  </strong>
                  <span class="icon is-mirroredX"> <FaIcon icon="history" /></span>
                </template>
                <template v-else>
                  <strong>{{ dayFormat(res.startDate) }}</strong>
                  <span class="icon is-inverted pb-3">
                    <FaIcon icon="calendar-check" />
                  </span>
                </template>
              </div>
            </div>
            <div class="columns is-mobile mb-0 is-gapless is-vcentered">
              <div class="column has-text-left pb-0" v-if="slot && slot.parkingFloor">
                <span> {{ $t('reservations.floor') }}: </span>
                <strong>{{ slot.parkingFloor.floorNumber }}</strong>
              </div>
              <div class="column has-text-right pb-0 is-narrow">
                <div
                  v-if="
                    userIs(['superadmin', 'parking_administration', 'company_admin']) &&
                    res.__user__ &&
                    res.__user__.email
                  "
                >
                  <strong class="is-uppercase has-text-primary">{{ res.__user__.email }}</strong>
                  <span class="icon has-text-primary"> <FaIcon icon="user-tie" /></span>
                </div>
              </div>
            </div>
            <div class="has-text-left pt-2 is-narrow" v-if="res.__guest__ && res.__guest__.email">
              <span>gość: </span>
              <strong class="">{{ res.__guest__.email }}</strong>
            </div>
          </div>
          <div class="has-text-left block pt-10">
            <span class="icon"> <FaIcon icon="parking" /> </span>
            <span>{{ lot && lot.spaceos_name }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import userMixin from '@/mixins/userMixin';
// import time from '../utils/time';
moment.locale('pl');

export default {
  data() {
    return {
      statusIcons: {
        reserved: 'check-circle',
        reserved_cancelled: 'times-circle',
        entering: 'sign-in-alt',
        entered: 'sign-in-alt',
        exiting: 'sign-out-alt',
        exited: 'sign-out-alt',
      },
    };
  },
  props: {
    res: Object,
    parking: Object,

    noImg: {
      default: false,
      type: Boolean,
    },
  },
  mixins: [userMixin],
  computed: {
    ...mapGetters({
      getSlot: 'parking/slots/getSlotById',
      getLot: 'parking/lots/getLotById',
    }),
    slot() {
      return this.getSlot(this.res.parkingSlotId);
    },
    lot() {
      return this.parking || this.getLot(this.res.parkingLotId);
    },
  },
  methods: {
    ...mapActions({
      fetchSlots: 'parking/slots/fetchSlotsIfEmpty',
      fetchLots: 'parking/lots/fetchLotsIfEmpty',
    }),
    timeFormat(t) {
      return moment(t).format('HH:mm');
    },
    dayFormat(day) {
      return moment(day).format('YYYY-MM-DD');
    },
    getWeekday(date) {
      return moment(date).format('dddd');
    },
  },
  mounted() {
    this.fetchSlots();
    this.fetchLots();
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';
.parking-image {
  object-fit: cover;
  object-position: bottom;

  &.center {
    object-position: center;
  }
}
.card-content {
  &.repeatable {
    border: 2px solid $primary;
    border-top: none;
  }
  &.noimg {
    border: 2px solid $primary;
  }
}

.spot-icon {
  transform: scale(0.75);
}
</style>
k
