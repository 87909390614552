import createGraph from 'ngraph.graph';
import path from 'ngraph.path';

const toTypes = ['parking_slot', 'road', 'exit'];
const fromTypes = ['road', 'entrance'];

const getDirection = (e) => (e.parkingSlot ? e.parkingSlot.direction : false);
const typeSelector = 'spaceType';
const getId = (e) => `${e.x}:${e.y}`;

function isDirection(element, direction) {
  const elDirection = getDirection(element);
  return elDirection ? elDirection === direction : true;
}

function compare(current, next, g, currDirection, nextDirection, weight) {
  if (
    fromTypes.includes(current[typeSelector]) &&
    toTypes.includes(next[typeSelector]) &&
    isDirection(next, nextDirection)
  ) {
    g.addLink(getId(current), getId(next), { weight });
  }
  if (
    fromTypes.includes(next[typeSelector]) &&
    toTypes.includes(current[typeSelector]) &&
    isDirection(current, currDirection)
  ) {
    g.addLink(getId(next), getId(current), { weight });
  }
}

function createGraphFromArray(arr) {
  const g = createGraph();

  arr.forEach((col, colIdx) => {
    col.forEach((cell, rowIdx) => {
      if (colIdx < arr.length - 1) {
        const next = arr[colIdx + 1][rowIdx];
        compare(cell, next, g, 'left', 'right', 10);
      }
      if (rowIdx < col.length - 1) {
        const next = arr[colIdx][rowIdx + 1];
        compare(cell, next, g, 'up', 'down', 5);
      }
    });
  });
  return g;
}

export default function calculatePathFromArray(array, startId, targetId) {
  const g = createGraphFromArray(array);
  const pathFinder = path.aStar(g, {
    oriented: true,
    distance(fromNode, toNode, link) {
      return link.data.weight;
    },
  });
  return pathFinder.find(startId, targetId);
}
