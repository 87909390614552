import { getUsers } from '@/utils/api/users';

export default {
  state() {
    return {
      users: [],
      loaded: false,
    };
  },
  mutations: {
    setUsers: (state, users) => {
      state.users = users;
      state.loaded = true;
    },
  },
  getters: {
    getLoaded: (state) => state.loaded,
    getUsers: (state) => state.users,
  },
  actions: {
    async fetchUsers({ commit }) {
      const response = await getUsers();
      commit('setUsers', response.data);
      return response.data;
    },
    async fetchUsersIfEmpty({ getters, dispatch }) {
      if (getters.getLoaded) return getters.getUsers;
      return dispatch('fetchUsers');
    },
  },
  namespaced: true,
};
