<template>
  <div class="box is-clickable parking p-4">
    <article class="media">
      <div class="media-left">
        <figure class="image is-square is-64x64">
          <img
            class="box p-0"
            :src="`/assets/img/parkings/` + parking.spaceos_location_id + '.jpg'"
            :alt="parking.title"
            onerror="this.src='/assets/img/parkings/garage.jpg'"
          />
        </figure>
      </div>
      <div class="media-content">
        <div class="content has-text-left">
          <div class="columns is-mobile">
            <div class="column">
              <p>
                <strong>{{ parking.spaceos_name }}</strong>
                <br />
                <span v-if="link" class="is-link">{{ link }}</span>
                <span v-else>
                  <span>{{ parking.spaceos_address }}</span>
                  <!-- <br />
                      <span>{{ parking.address2 }}</span> -->
                </span>
              </p>
            </div>
            <div class="column is-narrow is-flex is-justify-content-center is-align-items-center">
              <span v-if="current" class="icon has-text-primary">
                <FaIcon icon="check" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </article>
  </div>
</template>
<script>
export default {
  props: {
    parking: Object,
    link: String,
    current: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
