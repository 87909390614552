<template>
  <template v-for="(input, idx) in modelValue" :key="'field-' + idx">
    <div class="field">
      <p class="help has-text-left" :class="{ 'is-danger': input.isValid === false }">
        {{ input.label }} {{ input.isValid === false ? '- popraw to pole' : '' }}
      </p>
      <input
        :type="input.type"
        class="input"
        :placeholder="input.placeholder"
        :class="{ 'is-danger': input.isValid === false }"
        :value="input.value"
        @change="onChange($event, idx)"
      />
    </div>
  </template>
</template>
<!--
va

  -->
<script>
// input v-modal: lable, value, saveValue , validate, msg  [{},{},{}]
import isEmail from 'validator/lib/isEmail';
// import { ref } from 'vue';
const validator = { isEmail };
export default {
  data() {
    return {
      errorMsg: null,
      data() {
        return {
          name: '',
        };
      },
    };
  },
  props: {
    modelValue: Object,
  },

  methods: {
    onChange(event, id) {
      const inputs = this.modelValue;
      const fieldValue = event.target.value;
      const field = inputs[id];
      field.value = fieldValue;
      field.isValid = this.validate(field);
      this.$emit('update:modelValue', inputs);
    },
    validate(field) {
      for (let i = 0; i < field.validators.length; i += 1) {
        const validateFn = field.validators[i];
        const isValid = validator[validateFn.name](field.value, validateFn.options);
        if (isValid === false) return false;
      }
      return true;
    },
    validateAll() {
      const arr = Object.keys(this.modelValue);
      for (let i = 0; i < arr.length; i += 1) {
        const field = this.modelValue[arr[i]];
        const isValid = this.validate(field);
        field.isValid = isValid;
        if (isValid === false) return false;
      }
      return true;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/variables.scss';
.modal-content {
  max-width: calc($mobile-box-width - 50px);
}
.car {
  &:hover {
    @extend .has-background-primary-light;
  }
  &.current {
    border: 1px solid $primary;
  }
}
</style>
