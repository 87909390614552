<template>
  <div class="is-flex is-flex-direction-column is-justify-content-stretch h-full is-clipped">
    <h2 class="title is-5 mt-4">
      {{ $t('parkings.title') }}
    </h2>

    <scroll-box class="is-flex-grow-1">
      <div v-if="this.isLoaded == false">
        <Skeleton :elements="['img-text']"> </Skeleton>
      </div>
      <parking-box
        v-for="(parking, idx) in parkings"
        :parking="parking"
        @click="set(parking)"
        :key="idx"
        class="mx-1"
        :current="current.id === parking.id"
        :class="{ current: current && current.id === parking.id }"
      ></parking-box>
      <div class="block" v-if="isLoaded">
        <small class="is-size-6">{{ $t('parkings.helper') }}</small>
      </div>
    </scroll-box>

    <go-back />
  </div>
</template>
<script>
import { mapMutations, mapGetters, mapActions } from 'vuex';
import GoBack from '@/components/ui/GoBack';
import ScrollBox from '@/components/ui/layout/ScrollBox';
import ParkingBox from '@/components/ParkingBox';
import Skeleton from '@/components/ui/layout/Placeholders/Skeleton';

export default {
  data() {
    return {
      // isLoaded: false,
      settings: {
        maxScrollbarLength: 60,
      },
    };
  },
  computed: {
    ...mapGetters({
      current: 'parking/getCurrentParking',
      parkings: 'parking/lots/getLots',
      isLoaded: 'parking/lots/getLoaded',
    }),
  },
  methods: {
    ...mapActions({ fetchLots: 'parking/lots/fetchLots' }),
    ...mapMutations({ setParking: 'parking/setCurrentParking' }),
    set(parking) {
      this.setParking(parking);
      this.$router.back();
    },
    onLoad() {
      this.fetchLots();
      // this.isLoaded = false;
      // setTimeout(() => {
      //   this.isLoaded = true;
      //   this.items = this.parkings;
      // }, 3000);
    },
  },
  mounted() {
    this.onLoad();
  },
  components: {
    GoBack,
    ScrollBox,
    ParkingBox,
    Skeleton,
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/variables.scss';
.parking {
  // &:hover {
  //   @extend .has-background-primary-light;
  // }
  &.current {
    @extend .has-background-primary-light;
  }
}
</style>
