import { mapGetters, mapActions } from 'vuex';

export default {
  computed: {
    ...mapGetters({ user: 'auth/user/getUser' }),
  },
  methods: {
    ...mapActions({ fetchUser: 'auth/user/fetchIfEmpty' }),
    userIs(roles) {
      let roles2 = roles;
      if (!Array.isArray(roles)) {
        roles2 = [roles];
      }
      return this.user && this.user.role && roles2.includes(this.user.role);
    },
  },
  mounted() {
    this.fetchUser();
  },
};
