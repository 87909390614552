import { httpClient } from './httpClient';

const END_POINT = '/parking_slot_reservation';

export const getReservationsByDay = (day) =>
  httpClient.run.get(END_POINT, {
    params: {
      startDate: `${day} 00:00:01`,
      endDate: `${day} 23:59:59`,
      whitelistStatus: ['reserved', 'entering', 'entered', 'exiting', 'exited'],
      orderBy: 'idDesc',
    },
  });

export const getReservationsByUser = (userId) =>
  httpClient.run.get(END_POINT, {
    params: {
      userId,
      orderBy: 'idDesc',
      whitelistStatus: ['reserved', 'entering', 'entered', 'exiting', 'exited'],
    },
  });

export const getReservationsByIds = (whitelistIds) =>
  httpClient.run.get(END_POINT, {
    params: {
      whitelistIds,
      orderBy: 'idDesc',
      whitelistStatus: ['reserved', 'entering', 'entered', 'exiting', 'exited'],
    },
  });

export const getRepeatableForCompany = (companyId) =>
  httpClient.run.get(END_POINT, {
    params: {
      filterByRepeatEvery: 'day_and_week',
      companyId,
      orderBy: 'idDesc',
      whitelistStatus: ['reserved', 'entering', 'entered', 'exiting', 'exited'],
    },
  });

export const createReservation = ({
  startDate,
  endDate,
  parkingSlotId,
  overrideUserId,
  repeatEvery,
}) =>
  httpClient.run.post(
    END_POINT,
    {},
    {
      params: {
        startDate,
        endDate,
        parkingSlotId,
        overrideUserId,
        repeatEvery,
      },
    }
  );

export const deleteReservation = (id) =>
  httpClient.run.delete(END_POINT, {
    params: {
      id,
    },
  });

export const assignGuest = (id, email) =>
  httpClient.run.post(
    `${END_POINT}/assignGuestToReservation`,
    {},
    {
      params: {
        id,
        email,
      },
    }
  );
