<template>
  <div class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
    <h2 class="title is-5">{{ $t('login.title') }}</h2>
    <span class="subtitle is-6">{{ $t('login.loginWith') }}</span>
    <a :href="`${api_url}/auth/loginSpaceos?url_to_redirect=${currentUrl}`">
      <div class="image is-square logo">
        <img class="h-full" src="@/assets/img/officeme_logo.png" alt="" />
        <loader-effect class="logo-loader" :light="true" :rotate="true" />
      </div>
    </a>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import LoaderEffect from '@/components/ui/layout/Placeholders/LoaderEffect';

export default {
  data() {
    return {
      redirect: this.$route.query.redirect || '/',
      api_url: process.env.VUE_APP_API_URL,
    };
  },
  computed: {
    currentUrl() {
      return encodeURIComponent(
        `${window.location.origin.toString() + window.location.pathname.toString()}?redirect=${
          this.redirect
        }`
      );
    },
  },
  components: {
    LoaderEffect,
  },
  methods: {
    ...mapActions({ setTokenAPI: 'auth/setTokenAPI' }),
  },
  async mounted() {
    const { token } = this.$route.query;
    if (token) {
      console.log(`token: ${token}`);
      await this.setTokenAPI(token);
      this.$router.replace(this.redirect);
    }
  },
};
</script>
<style lang="scss" scoped>
.logo {
  width: 88px;
  &:not(:hover) {
    .logo-loader {
      display: none;
    }
  }
}
</style>
