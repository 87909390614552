import { httpClient } from './httpClient';

const END_POINT = '/user';

export const setAllowEntryWithoutReservation = (userId, canEntry) =>
  httpClient.run.put(
    `${END_POINT}`,
    {},
    {
      params: {
        userId,
        allowEntryWithoutReservation: canEntry ? 1 : 0,
      },
    }
  );
