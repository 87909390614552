import {
  httpClient
} from './httpClient';

const END_POINT = '/user';

export const getQr = () => {
  console.log('get qr')
  return httpClient.run.get(`${END_POINT}/findReservationStartStopQrCodeLoggedIn`,
  {
    responseType: 'arraybuffer'
  }

  )
};
