import { httpClient } from './httpClient';

const END_POINT = '/parking_empty_slot';

export const createParkingEmptyParkingSlot = (x, y, floorId, spaceType) => {
  console.log(x, y, floorId, spaceType);
  return httpClient.run.post(
    `${END_POINT}/createParkingEmptyParkingSlot`,
    {},
    {
      params: {
        parkingFloorId: floorId,
        spaceType,
        x,
        y
      }
    }
  );
};
