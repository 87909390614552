<template>
  <div class="wrapper">
    <!-- <div>
      <current-parking class="mb-6" />
    </div> -->
    <div class="columns is-mobile is-centered my-1">
      <div class="column">
        <button
          class="button is-small is-primary is-inverted has-background-light"
          @click="addDay(-1)"
        >
          <span class="icon">
            <FaIcon icon="chevron-left" />
          </span>
        </button>
      </div>
      <div class="column">
        <date-picker
          v-model="day"
          ref="picker"
          :enableTimePicker="false"
          :autoApply="true"
          locale="pl"
        >
          <template #trigger>
            <button class="button is-small is-primary is-outlined has-background-light">
              {{ formattedDay }}
            </button>
          </template>
        </date-picker>
      </div>
      <div class="column">
        <button
          class="button is-small is-primary is-inverted has-background-light"
          @click="addDay(1)"
        >
          <span class="icon">
            <FaIcon icon="chevron-right" />
          </span>
        </button>
      </div>
    </div>
    <p v-if="getSlots.length">{{ $t('reservations.addHandler') }}</p>
    <transition name="fade-slow" v-if="!isLoaded" append>
      <Skeleton
        :elements="['sm-text', 'sm-text', 'sm-text', 'sm-text', 'sm-text']"
        :no-box="true"
        :no-padding="true"
        :no-shadows="false"
        :style="{ 'padding-top': blockHeight + 'px' }"
        :loaderInElements="true"
      />
    </transition>
    <scroll-box
      :no-height="true"
      v-else-if="getSlots.length"
      class="scrollbox is-clipped is-relative is-flex-grow-1"
      :settings="settingsY"
      v-dragscroll
    >
      <div class="columns is-mobile column-wrapper m-0">
        <div
          class="has-text-left column is-narrow p-0 slot-column"
          :style="{ 'margin-top': blockHeight + 'px' }"
        >
          <div
            :class="{ 'is-invisible': !isLoaded }"
            class="slot"
            v-for="(slot, idx) in getSlots"
            :key="'slot-' + idx"
            :style="{ height: blockHeight + 'px' }"
          >
            {{ slot.parkingSlotNumber }}/{{ slot.parkingFloor.floorNumber }}
          </div>
        </div>
        <scroll-box
          :no-height="true"
          class="scrollbox2 is-clipped is-relative column is-flex is-flex-direction-column p-0 ml-2"
          :class="{ 'is-clipped': !isLoaded }"
          :settings="settingsX"
          v-dragscroll
        >
          <div
            class="is-flex hour-columns is-mobile"
            :style="{ 'padding-left': blockWidth / 2 + 'px' }"
          >
            <div
              class="p-0 hour-wrapper"
              v-for="hour in 24"
              :key="'hour-label-' + hour"
              :style="{ width: blockWidth + 'px' }"
            >
              <span
                class="hour-label has-text-center"
                v-show="!(blockWidth < 20 && hour % 2 === 0)"
                >{{ hour }}</span
              >
            </div>
          </div>
          <div class="columns is-mobile m-0 hour-grid" :style="`width: ${blockWidth * 24}px`">
            <div class="hour column p-0" v-for="hour in 24" :key="'hour-' + hour"></div>
            <div class="current-hour" :style="{ width: currentHourLeftOffset }"></div>
          </div>
          <div class="clicks">
            <template v-if="isLoaded">
              <div
                class="p-0 is-flex is-align-items-center"
                v-for="(slot, idx) in getSlots"
                :key="'slot' + idx"
                :style="{
                  position: 'absolute',
                  top: (idx + 0.5) * blockHeight + 10 + 'px',
                  width: '100%',
                }"
              >
                <template
                  v-for="res in parseSlot(getReservationsByDayAndSlot(formattedDay, slot.id))"
                  :key="`res-` + res"
                >
                  <button
                    v-if="res.dummy"
                    class="p-0 button dummy is-primary is-flex-shrink-0 box m-0 is-rounded"
                    :style="getStyle(res)"
                    @click="createReservation(res, slot)"
                  >
                    +
                  </button>
                  <button
                    v-else
                    class="
                      reservation
                      box
                      button
                      is-primary
                      p-0
                      m-0
                      is-flex-grow-0 is-flex-shrink-0
                    "
                    :class="
                      userIs(['superadmin', 'parking_administration', 'company_admin']) ||
                      res.userId === currentUser
                        ? 'mine is-rounded'
                        : ''
                    "
                    :disabled="
                      res.userId !== currentUser &&
                      !userIs(['superadmin', 'parking_administration', 'company_admin'])
                    "
                    :style="getStyle(res)"
                    @click="editReservation(res)"
                  >
                    <span v-if="res.repeatEvery" class="icon is-mirroredX" style="opacity: 0.5">
                      <FaIcon icon="history" />
                    </span>
                    <span v-if="res.userId === currentUser" class="icon">
                      <FaIcon icon="user" />
                    </span>
                    <span
                      v-else-if="userIs(['superadmin', 'parking_administration', 'company_admin'])"
                      class="icon"
                    >
                      <FaIcon icon="users" />
                    </span>
                  </button>
                </template>
              </div>
            </template>
          </div>
        </scroll-box>
      </div>
    </scroll-box>
    <div v-else class="is-flex-grow-1 is-flex is-justify-content-center is-align-items-center">
      <p>{{ $t('reserv.noAcces') }}</p>
      <p>{{ $t('reserv.askAdmin') }}</p>
    </div>
    <div class="is-flex is-justify-content-space-between is-align-items-center mt-3 is-mobile">
      <div class="has-text-left">
        <go-back class="pl-0" />
      </div>
      <div class="has-text-right">
        <button
          class="button is-small is-primary is-inverted has-background-light"
          :disabled="this.blockWidth <= 10"
          @click="zoomOut"
        >
          <span class="icon">
            <FaIcon icon="search-minus" />
          </span>
        </button>
        <button
          class="button is-small is-primary is-inverted has-background-light"
          :disabled="this.blockWidth >= 50"
          @click="zoomIn"
        >
          <span class="icon">
            <FaIcon icon="search-plus" />
          </span>
        </button>
      </div>
    </div>
    <reservation-modal ref="reservationModal" @update="fetchReservations(formattedDay)" />
  </div>
</template>

<script>
import moment from 'moment';
import { defineAsyncComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import GoBack from '@/components/ui/GoBack';
import ReservationModal from '@/components/ReservationModal';
import Skeleton from '@/components/ui/layout/Placeholders/Skeleton';
import ScrollBox from '@/components/ui/layout/ScrollBox';
import userMixin from '@/mixins/userMixin';

export default {
  mixins: [userMixin],
  data() {
    return {
      slot: [],
      settingsX: {
        suppressScrollY: true,
      },
      settingsY: {
        suppressScrollX: true,
      },
      blockHeight: 40,
      blockWidth: 30,
      day: moment(),
      showAdd: false,
      loading: false,
      parsedReservations: [],
      now: this.toHours(moment()),
    };
  },
  computed: {
    ...mapGetters({
      getReservationsByDayAndSlot: 'parking/reservations/getReservationsByDayAndSlot',
      getReservationsByDayLoaded: 'parking/reservations/getReservationsByDayLoaded',
      getSlots: 'parking/slots/getSlots',
      user: 'auth/user/getUser',
    }),
    isLoaded() {
      return this.getReservationsByDayLoaded(this.formattedDay);
    },
    currentUser() {
      return this.user.id;
    },
    todayMoment() {
      return moment.isMoment(this.day) ? this.day : moment(this.day);
    },
    formattedDay() {
      return this.todayMoment.format('YYYY-MM-DD');
    },
    // !!!! Testing reservations table, uncomment only for development
    // testParsed() {
    //   return this.getSlots
    //     .slice()
    //     .map((e) => this.parseSlot(this.getReservationsByDayAndSlot(this.formattedDay, e.id)));
    // },
    currentHourLeftOffset() {
      return `${moment.duration(this.now).asHours() * this.blockWidth}px`;
    },
  },
  methods: {
    ...mapActions({
      fetchReservations: 'parking/reservations/fetchReservationsByDay',
      fetchSlots: 'parking/slots/fetchSlots',
    }),
    getDummy(dummy) {
      const arr = [];
      /* eslint-disable no-param-reassign */

      const startBefore = moment(`${this.formattedDay} ${dummy.start}`).isSameOrBefore(
        `${this.formattedDay} ${this.now}`
      );
      const endBefore = moment(`${this.formattedDay} ${dummy.end}`).isSameOrBefore(
        `${this.formattedDay} ${this.now}`
      );
      if (startBefore) {
        if (endBefore) {
          dummy.hidden = true;
        } else {
          arr.push({
            start: dummy.start,
            end: this.now,
            dummy: true,
            hidden: true,
          });
          dummy.start = this.now;
        }
      }
      /* eslint-disable no-param-reassign */

      arr.push(dummy);
      return arr;
    },
    parseSlot(slot) {
      const sorted = slot.slice();
      sorted.sort((a, b) => moment(a.startDate).format('X') - moment(b.startDate).format('X'));
      /* eslint-disable no-param-reassign */
      let reduced = sorted.reduce((acc, curr, idx, arr) => {
        if (curr.repeatEvery) {
          //  we subtract 1 ms from date to avoid 00:00:00/24:00:00 confusion
          const startDay = moment(curr.startDate).subtract(1, 'ms');
          const endDay = moment(curr.endDate).subtract(1, 'ms');
          if (curr.repeatEvery === 'week') {
            const currentWeekDay = this.todayMoment.day();
            const isBeetwen = currentWeekDay >= startDay.day() && currentWeekDay <= endDay.day();
            const isCircling = startDay.day() > endDay.day() && startDay.day() >= currentWeekDay;
            if (!isBeetwen && !isCircling) {
              return acc;
            }

            if (startDay.day() === this.todayMoment.day()) {
              curr.newStartDate = `${this.formattedDay} ${startDay.format('HH:mm:ss')}`;
            }
            if (startDay.day() < this.todayMoment.day()) {
              curr.newStartDate = `${moment(this.formattedDay)
                .subtract(1, 'd')
                .format('YYYY-MM-DD')} ${startDay.format('HH:mm:ss')}`;
            }
            if (endDay.day() === this.todayMoment.day()) {
              curr.newEndDate = `${this.formattedDay} ${endDay.format('HH:mm:ss')}`;
            }
            if (endDay.day() > this.todayMoment.day()) {
              curr.newEndDate = `${moment(this.formattedDay)
                .add(1, 'd')
                .format('YYYY-MM-DD')} ${endDay.format('HH:mm:ss')}`;
            }
          }
          if (curr.repeatEvery === 'day') {
            if (startDay.day() !== endDay.day()) {
              // if reservation passing day add reservation at end od array and let it go
              const endRes = {
                ...curr,
              };
              endRes.startDate = `${this.formattedDay} ${startDay.format('HH:mm:ss')}`;
              endRes.endDate = moment(this.formattedDay).endOf('day').format('HH:mm:ss');
              arr.push(endRes);

              // original reservation is at start od the day
              curr.newStartDate = `${this.formattedDay} ${moment(this.formattedDay)
                .startOf('day')
                .format('HH:mm:ss')}`;
              curr.newEndDate = `${this.formattedDay} ${endDay.format('HH:mm:ss')}`;
            } else {
              // else reservation is in day range
              curr.newStartDate = `${this.formattedDay} ${startDay.format('HH:mm:ss')}`;
              curr.newEndDate = `${this.formattedDay} ${endDay.format('HH:mm:ss')}`;
            }
          }
        }
        curr.start = this.toHours(curr.newStartDate || curr.startDate);
        curr.end = this.toHours(curr.newEndDate || curr.endDate);
        const dummy =
          idx === 0
            ? {
                start: '00:00:00',
                end: curr.start,
                dummy: true,
                hidden: false,
              }
            : {
                start: acc[acc.length - 1].end,
                end: curr.start,
                dummy: true,
                hidden: false,
              };
        const dummies = this.getDummy(dummy);
        acc.push(...dummies);
        acc.push(curr);
        if (idx === arr.length - 1 && curr.end !== '24:00:00') {
          acc.push(
            ...this.getDummy({
              start: curr.end,
              end: '24:00:00',
              dummy: true,
            })
          );
        }
        return acc;
      }, []);
      /* eslint-disable no-param-reassign */
      if (!reduced.length) {
        const dummies = this.getDummy({
          start: '00:00:00',
          end: '24:00:00',
          dummy: true,
        });
        reduced = [...dummies];
      }
      return reduced;
    },
    toHours(date) {
      const mom = moment(date);
      if (mom.isBefore(`${this.formattedDay} 00:00:01`)) return '00:00:00';
      if (mom.isAfter(`${this.formattedDay} 23:59:59`)) return '24:00:00';
      return mom.format('HH:mm:ss');
    },
    getStyle(reservation) {
      const start = moment.duration(reservation.start).asHours();
      const end = moment.duration(reservation.end).asHours();
      const duration = end - start;
      const style = {
        width: `${Math.max(0, duration * this.blockWidth)}px`,
        height: `${this.blockHeight - 4}px`,
        visibility:
          reservation.hidden || (reservation.dummy && duration <= 0.5) || duration <= 1 / 60
            ? 'hidden'
            : 'visible',
      };
      return style;
    },
    addDay(num) {
      this.day = moment(this.day).add(num, 'days');
    },
    zoomIn() {
      if (this.blockWidth < 50) this.blockWidth += 10;
    },
    zoomOut() {
      if (this.blockWidth > 10) this.blockWidth -= 10;
    },
    createReservation(res, slot) {
      this.$refs.reservationModal.add(res, this.formattedDay, slot);
    },
    editReservation(res) {
      this.$refs.reservationModal.edit(res, this.formattedDay);
    },
    onAddReservation() {
      this.fetchReservations(this.formattedDay);
      // console.log(res);
      // const slotIdx = this.slot.findIndex(({ id }) => id === res.slot);
      // console.log(slotIdx);
    },
  },
  components: {
    GoBack,
    // PerfectScrollbar,
    ScrollBox,
    DatePicker: defineAsyncComponent(() => import('@/components/DatePicker')),
    ReservationModal,
    Skeleton,
  },
  watch: {
    formattedDay() {
      this.fetchReservations(this.formattedDay);
    },
  },
  mounted() {
    this.fetchReservations(this.formattedDay);
    this.fetchSlots();
    this.interval = setInterval(() => {
      this.now = this.toHours(moment());
    }, 3 * 1000);
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';
.wrapper {
  @include mobileAndShort() {
    height: 100%;
    justify-content: stretch;
    display: flex;
    flex-direction: column;
  }
}
.hour-grid {
  height: 100%;
  .hour {
    height: 100%;
    width: 20px;
    border-left: 1px solid;
    @extend .has-text-grey-light;
    z-index: 1;
  }
  .current-hour {
    height: 100%;
    position: absolute;
    background-color: $grey-lighter;
    top: 0;
    left: 0;
    pointer-events: none;
    &:after {
      content: '';
      position: absolute;
      width: 2px;
      height: 100%;
      top: 0;
      right: 0;
      background-color: $primary;
      animation: blink 2s linear infinite;
    }
  }
}

.clicks {
  z-index: 1;
  .dummy {
    opacity: 0;
    transition: opacity 0.3s;

    &:hover {
      opacity: 1;
    }
  }
  .reservation {
    &:not(.mine) {
      background: $primary;
      // background: repeating-linear-gradient(
      //   45deg,
      //   $primary,
      //   $primary 2px,
      //   $primary-light 2px,
      //   $primary-light 14px
      // );
      border: 0px;
      border-radius: 0;
      pointer-events: none;
    }
    &.mine {
      // background: #8897c3;
      border: none;
      // &:hover {
      //   // background: $primary;
      // }
    }
  }
}

.hour-columns {
  .hour-wrapper {
    flex-shrink: 0;
  }
  z-index: 1;
}

.scrollbox {
  @include highTablet {
    max-height: 290px;
    min-height: 290px;
  }
  height: 50px;

  .column-wrapper {
    width: 100%;
  }
}

::v-deep {
  .ps__rail-y,
  .ps__rail-x {
    display: none;
  }
}
</style>
