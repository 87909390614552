import { httpClient } from './httpClient';

const END_POINT = '/parking_floor';

export const getFloorList = (parkingId) =>
  httpClient.run.get(END_POINT, {
    params: {
      parkingLotId: parkingId,
      displayParkingLayout2d: 0
    }
  });

export const getFloorDetails = (floorId) =>
  httpClient.run.get(END_POINT, {
    params: {
      id: floorId,
      displayParkingLayout2d: 1
    }
  });
