<template lang="">
  <modal v-model="openModal">
    <component
      v-if="component"
      :is="component"
      :res="res"
      :day="day"
      :slot-obj="slotObj"
      :guest="guest"
      @reservationAdded="onReservationAdded"
      @addGuest="addGuest"
      @close="openModal = false"
      @guestAdded="onGuestAdded"
      @reservationDeleted="onReservationDeleted"
    />
  </modal>
</template>

<script>
import { mapGetters } from 'vuex';
import Modal from '@/components/ui/layout/Modal';
import AddReservation from './AddReservation';
import ReservationAdded from './ReservationAdded';
import AddGuest from './AddGuest';
import guestAdded from './guestAdded';
import onEdit from './onEdit';
// import {h} from 'vue'
export default {
  name: 'ReservationModal',
  emits: [''],
  data() {
    return {
      openModal: false,
      res: null,
      component: null,
      day: null,
      slotObj: null,
      guest: null,
    };
  },
  components: {
    Modal,
    AddReservation,
    ReservationAdded,
    AddGuest,
    guestAdded,
    onEdit,
  },
  computed: {
    ...mapGetters({ parking: 'parking/getCurrentParking' }),
  },
  watch: {
    openModal(val) {
      if (val === false) {
        this.component = null;
        this.res = null;
      }
    },
  },
  methods: {
    add(res, day, slot) {
      this.res = res;
      this.day = day;
      this.slotObj = slot;
      this.component = 'AddReservation';
      this.openModal = true;
    },
    edit(res, day) {
      this.res = res;
      this.day = day;
      this.component = 'onEdit';
      this.openModal = true;
    },
    onReservationAdded(res) {
      this.$emit('update');
      this.res = res;
      this.component = 'ReservationAdded';
    },
    onReservationDeleted() {
      this.$emit('update');
      this.openModal = false;
    },
    addGuest() {
      this.component = 'AddGuest';
    },
    onGuestAdded(guest) {
      this.guest = guest;
      this.component = 'guestAdded';
    },
  },
};
</script>
