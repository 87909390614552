<template>
  <div class="column is-grey-lighter" v-if="selectedUser !== Object">
    <scroll-box>
      <div>
        <h1 class="bold is-size-4" @click="showUserData = ~showUserData">
          {{ selectedUser.name }}
          <div class="icon">
            <FaIcon class="m-4" :icon="[showUserData ? 'chevron-down' : 'chevron-up']" />
          </div>
        </h1>
        <div class="has-text-left" v-show="showUserData">
          <ul>
            <li>{{ selectedUser.email }}</li>
            <li>{{ selectedUser.role }}</li>
          </ul>
        </div>
      </div>
      <div class="mt-5">
        <h1>{{ $t('parkings.canEnter') }}</h1>

        <div class="buttons has-addons is-primary">
          <button
            class="button"
            v-bind:class="{
              ' button is-primary': enabled,
            }"
            @click="
              this.allowEntry(selectedUser.id, 1);
              enabled = true;
            "
          >
            {{ $t('admin.yes') }}
          </button>
          <button
            class="button"
            v-bind:class="{
              'button is-primary': !enabled,
            }"
            @click="
              allowEntry(selectedUser.id, 0);
              enabled = false;
            "
          >
            {{ $t('admin.no') }}
          </button>
        </div>
      </div>
      <h1 class="bold is-size-4" @click="showUserReservations = ~showUserReservations">
        {{ $t('reservations.reservation') }}
        <div class="icon">
          <FaIcon class="m-4" :icon="[showUserReservations ? 'chevron-down' : 'chevron-up']" />
        </div>
      </h1>

      <div class="m-1" v-show="showUserReservations">
        <Skeleton class="mr-2" v-if="loadingRes" :elements="['lg-text']"> </Skeleton>
        <ReservationsBox
          heigth="50"
          v-for="(res, idx) in UserResList"
          :res="res"
          :key="idx"
          :noImg="true"
        ></ReservationsBox>
        <div class="has-text-left" v-if="this.noRes && !loadingRes">
          <h1 class="bold is-size-5">
            {{ 'reservations.noReservations' }}
          </h1>
        </div>
      </div>
    </scroll-box>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
// eslint-disable-next-line no-unused-vars
import { getReservationsByUser } from '@/utils/api/parking_slot_reservation';
import ScrollBox from '@/components/ui/layout/ScrollBox';
import { setAllowEntryWithoutReservation } from '@/utils/api/user';
import ReservationsBox from '@/components/ReservationsBox';
import Skeleton from '@/components/ui/layout/Placeholders/Skeleton';

export default {
  data() {
    return {
      showUserData: Boolean,
      showUserReservations: Boolean,
      enabled: true,
      loadingRes: true,
      UserResList: [],
      noRes: false,
    };
  },
  components: {
    ScrollBox,
    ReservationsBox,
    Skeleton,
  },
  watch: {
    selectedUser() {
      this.enabled = this.selectedUser.allowEntryWithoutReservation;
      this.loadingRes = true;
      this.UserResList = this.getResByUserId(this.selectedUser.id);
    },
  },
  computed: {
    ...mapGetters({
      users: 'admin/getUsers',
      companies: 'companies/getCompanies',
      getSlot: 'parking/slots/getSlotById',
      getLot: 'parking/lots/getLotById',
    }),
    slot() {
      return this.getSlot(this.res.parkingSlotId);
    },
    lot() {
      return this.parking || this.getLot(this.res.parkingLotId);
    },
  },

  methods: {
    ...mapActions({
      fetchUsers: 'admin/fetchUsers',
      fetchCompanies: 'companies/fetchCompanies',
      fetchSlots: 'parking/slots/fetchSlotsIfEmpty',
      fetchLots: 'parking/lots/fetchLotsIfEmpty',
    }),

    async allowEntry(id, status) {
      this.$parent.loading = true;
      await setAllowEntryWithoutReservation(id, status);

      this.fetchUsers();
      this.$parent.loading = false;
    },
    async getResByUserId(id) {
      this.loadingRes = true;
      const res = await getReservationsByUser(id);
      this.loadingRes = false;
      this.UserResList = res.data;
      if (this.UserResList.length === 0) {
        this.noRes = true;
      }
      this.loadingRes = false;
    },
    // getParking(id) {},
    edit(res) {
      this.$refs.reservationModal.edit(res);
    },
  },
  props: {
    selectedUser: null,
    loading: Boolean,
  },
  mounted() {
    this.fetchSlots();
    this.fetchLots();
  },
};
</script>
<style lang="scss" scoped>
.is-primary {
}
</style>
