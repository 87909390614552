<template>
  <div class="box is-clickable car">
    <div class="columns is-mobile">
      <div class="column has-text-left">
        <p class="strong">{{ plate.plateNumber }}</p>
      </div>
      <div class="column has-text-right">
        <button @click="$emit('delete', plate.id)" class="button is-inverted icon is-danger">
          <span class="icon"><FaIcon icon="trash-alt" /></span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    plate: Object,
  },
};
</script>
