<template>
  <div
    class="
      wrapper
      is-flex is-flex-direction-column is-justify-content-stretch is-align-items-stretch
    "
  >
    <!-- <img src="@/assets/img/office-me-top.jpg" class="is-hidden-high-tablet top-bar" alt="" /> -->
    <router-view class="is-flex-grow-1 router-view" />
    <!-- <img src="@/assets/img/office-me-bottom.jpg" class="is-hidden-high-tablet bottom-bar" alt="" /> -->
  </div>
</template>
<style lang="scss" scoped>
.wrapper {
  height: var(--app-height);
  .top-bar {
    z-index: 10;
  }
  .router-view {
    z-index: 1;
  }
}
</style>
