<template>
  <div class="columns is-overlay">
    <div class="column is-three-fifth is-clipped is-flex is-flex-direction-column ml-5">
      <div class="tabs is-centered is-flex-shrink-0">
        <ul>
          <strong>{{ $t('invitation.floors') }}</strong>
          <li
            v-for="(floor, floorIdx) in floorList"
            :class="{ 'is-active': floor.id === currentFloorId }"
            @click="selectFloor(floor.id)"
            :key="'floor-header' + floorIdx"
          >
            <a class="button is-ghost" :class="{ 'is-loading': getFloorDetailsLoading(floor.id) }"
              >{{ floor.floorNumber }}
            </a>
          </li>
        </ul>
      </div>
      <div class="is-scroll h-full" @scroll="onScroll" ref="scrollbar">
        <template v-for="(floor, floorIdx) in floorList" :key="'floor-details' + floorIdx">
          <parking-map
            :ref="`parkingMap` + floor.id"
            v-if="floor.id === currentFloorId"
            :floor-id="floor.id"
            :lot-id="currentParking.id"
            v-model:selectedSlot="selectedSlotPosition"
            :viewportRender="true"
            :scale="scale"
            :viewport="{
              left: scrollLeft,
              top: scrollTop,
              right: offsetWidth + scrollLeft,
              bottom: offsetHeight + scrollTop,
            }"
          />
        </template>
      </div>
    </div>
    <div class="column is-two-fifth has-background-light is-scroll">
      <div class="pt-3">
        <slot-details
          :slot-position="selectedSlotPosition"
          :floor-id="currentFloorId"
          @update="refetchFloor"
          @calculatePath="
            $refs[`parkingMap${currentFloorId}`][0].calculatePath(selectedSlotPosition)
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ParkingMap from '@/components/ParkingMap';
import SlotDetails from '@/components/ParkingMap/SlotDetails';

export default {
  data() {
    return {
      currentFloorId: null,
      selectedSlotPosition: null,
      scrollLeft: 0,
      scrollTop: 0,
      offsetWidth: 0,
      offsetHeight: 0,
      scale: 1,
    };
  },
  components: {
    ParkingMap,
    SlotDetails,
  },
  computed: {
    ...mapGetters({
      currentParking: 'parking/getCurrentParking',
      getFloorList: 'parking/floors/getFloorList',
      getFloorDetailsLoading: 'parking/floors/getFloorDetailsLoading',
    }),
    floorList() {
      return this.currentParking.id ? this.getFloorList(this.currentParking.id) : [];
    },
  },
  methods: {
    ...mapActions({ fetchFloorList: 'parking/floors/fetchFloorList' }),
    refetchFloor() {
      this.$refs[`parkingMap${this.currentFloorId}`][0].fetch();
    },
    selectFloor(id) {
      this.currentFloorId = id;
      this.$nextTick(() => {
        this.setViewport(this.$refs.scrollbar);
      });
    },
    onScroll(event) {
      this.setViewport(event.target);
    },
    setViewport(target) {
      this.scrollTop = target.scrollTop;
      this.scrollLeft = target.scrollLeft;
      this.offsetHeight = target.offsetHeight;
      this.offsetWidth = target.offsetWidth;
    },
  },
  mounted() {
    if (this.currentParking && this.currentParking.id) {
      this.fetchFloorList(this.currentParking.id);
    } else {
      this.$router.push('/parking/select');
    }
  },
};
</script>
