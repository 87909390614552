<template>
  <div class="loader-wrapper block animate" :class="{ box: !noBox, 'p-4': !noPadding }">
    <template v-for="(el, idx) in elements" :key="idx">
      <div v-if="el === 'img-text'" class="block">
        <div class="columns is-mobile">
          <div class="column is-narrow">
            <span class="element box p-0 block img-square"
              ><LoaderEffect v-if="loaderInElements" :medium="true"
            /></span>
          </div>
          <div class="column is-flex is-flex-direction-column is-justify-content-space-between">
            <span class="element p-0 sm-text" :class="{ 'is-shadowless': noShadows }"
              ><LoaderEffect v-if="loaderInElements" :medium="true"
            /></span>
            <span class="element p-0 sm-text" :class="{ 'is-shadowless': noShadows }"
              ><LoaderEffect v-if="loaderInElements" :medium="true"
            /></span>
          </div>
        </div>
      </div>
      <!-- <div v-else-if="el === 'card-img'"> </div> -->
      <!-- <div v-if="el === 'element card-img'"> </div>
                <div v-if="el === 'element lg-text'"> </div> -->
      <div v-else-if="el === 'icon-text'" class="columns is-mobile mb-0">
        <div class="column is-flex is-flex-direction-column is-justify-content-space-between">
          <span class="element p-0 mb-3 sm-text" :class="{ 'is-shadowless': noShadows }"
            ><LoaderEffect v-if="loaderInElements" :medium="true"
          /></span>
          <span class="element p-0 sm-text" :class="{ 'is-shadowless': noShadows }"
            ><LoaderEffect v-if="loaderInElements" :medium="true"
          /></span>
        </div>
        <div class="column is-narrow is-flex is-flex-direction-column is-justify-content-center">
          <span class="element box p-0 block image is-32x32" :class="{ 'is-shadowless': noShadows }"
            ><LoaderEffect v-if="loaderInElements" :medium="true"
          /></span>
        </div>
      </div>
      <span v-else class="element box block p-0" :class="el + (noShadows ? ' is-shadowless' : '')"
        ><LoaderEffect v-if="loaderInElements" :medium="true"
      /></span>
    </template>
    <LoaderEffect v-if="!loaderInElements" />
  </div>
</template>

<script>
import LoaderEffect from './LoaderEffect';

export default {
  components: {
    LoaderEffect,
  },
  props: {
    elements: Array,
    noBox: {
      type: Boolean,
      default: false,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    noShadows: {
      type: Boolean,
      default: true,
    },
    loaderInElements: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/variables.scss';
.loader-wrapper {
  z-index: 10;
  width: 100%;
  overflow: hidden;
  position: relative;
}
.element {
  background: $grey-lighter;
  @extend .is-relative;
  &.sm-text {
    height: 24px;
  }
  &.lg-text {
    height: 48px;
  }
  &.img-square {
    height: 64px;
    width: 64px;
  }
  &.card-img {
    height: 82px;
    margin-top: -1rem;
    margin-left: -1rem;
    margin-right: -1rem;
    border-radius: 0;
  }
  &.qr-code {
    height: 264px;
    width: 100%;
  }
}
</style>
