<template>
  <div :class="{ 'has-text-left': left }">
    <button @click="goBack" class="button is-link is-inverted">
      <span class="icon">
        <FaIcon icon="chevron-left" />
      </span>
      <span class="">{{ text || 'Wróć' }}</span>
    </button>
  </div>
</template>
<script>
export default {
  props: {
    to: String,
    text: String,
    left: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    goBack() {
      if (this.to) {
        this.$router.push(this.to);
      } else {
        this.$router.back();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
button {
  background-color: transparent !important;
}
</style>
