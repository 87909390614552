<template>
  <div class="loader-effect" :class="{ light, slow, rotate, medium, primary }"></div>
</template>
<script>
export default {
  props: {
    light: {
      type: Boolean,
      default: false,
    },
    medium: {
      type: Boolean,
      default: false,
    },
    slow: {
      type: Boolean,
      default: false,
    },
    rotate: {
      type: Boolean,
      default: false,
    },
    primary: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/variables.scss';
.loader-effect {
  @extend .is-overlay;
  animation-duration: 2.1s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: leftToRight;
  animation-timing-function: cubic-bezier(0.23, 0.99, 0.6, 0.91);
  overflow: hidden;
  &.slow {
    animation-duration: 4.2s;
  }
  &:after {
    content: '';
    @extend .is-overlay;
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 20%,
      #fff 50%,
      rgba(255, 255, 255, 0) 80%
    );
  }
  &.light:after {
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 30%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0) 70%
    );
  }
  &.medium:after {
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 30%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 70%
    );
  }
  &.primary:after {
    background-image: linear-gradient(
      to right,
      rgba(247, 249, 251, 0) 30%,
      rgba(247, 249, 251, 0.5) 50%,
      rgba(247, 249, 251, 0) 70%
    );
  }
  &.rotate:after {
    transform: rotate(25deg) scaleY(2.5);
  }
}

@keyframes leftToRight {
  0% {
    transform: translate(-100%);
  }
  100% {
    transform: translate(100%);
  }
}
</style>
