<template>
  <div
    class="
      h-screen
      w-screen
      is-flex is-flex-firection-column is-align-items-stretch is-justify-content-center
      has-background-white
    "
  >
    <nav
      class="navbar is-primary has-shadow is-fixed-top"
      role="navigation"
      aria-label="main navigation"
    >
      <div class="navbar-brand is-justify-content-center">
        <div class="navbar-item" to="/">
          <img src="@/assets/img/parkme_logo_white.svg" width="112" />
        </div>
      </div>
    </nav>
    <scroll-box class="scrollbar is-clipped is-flex-grow-1 h-full" :no-height="true">
      <div
        class="
          container
          is-clipped
          has-text-centered
          is-flex is-flex-direction-column is-justify-content-center is-align-items-center
        "
      >
        <section class="section">
          <div>
            <h2 class="title is-5 mb-0 mt-3">{{ $t('invitation.share') }}</h2>
            <p v-if="notFound" class="mt-3">
              <span>
                {{ $t('invitation.error') }} .<br />
                {{ $t('invitation.contact') }}</span
              >
            </p>
            <p class="mt-3" v-if="reservation">
              <strong>{{ day }}</strong> <span> {{ hours }}</span>
            </p>
            <p class="mt-3" v-if="parking">
              <a
                :href="mapLink"
                class="button is-ghost has-backgorund-color-white is-flex-direction-column"
                rel="noopener noreferrer"
                target="_blank"
              >
                <strong class="">
                  <span class="icon">
                    <FaIcon icon="map-marker-alt" />
                  </span>
                  {{ parking.spaceos_name }}
                </strong>
                <span class="">
                  {{ parking.spaceos_address }}
                </span>
              </a>
            </p>
          </div>
        </section>
        <section class="section" v-if="reservation && reservation.securityToken">
          <p class="">{{ $t('invitation.scan') }}</p>
          <qrcode-vue
            class="qr-code my-4"
            render-as="svg"
            :size="300"
            :value="`_psr_ms_${reservation.id}_${reservation.securityToken}`"
          >
          </qrcode-vue>
        </section>
        <section class="section" v-if="loggedIn">
          <div>
            <span class="label"> {{ $t('invitation.addlicencePlate') }}</span>
            <button class="button is-primary" @click="openModal = true">
              <span class="icon">
                <FaIcon icon="car" />
              </span>
              <span> {{ $t('invitation.add') }}</span>
            </button>
          </div>
        </section>
        <!-- <div> -->
        <section class="section" v-if="slot">
          <p>
            {{ $t('invitation.level') }}:
            <strong> {{ slot.parkingFloor && slot.parkingFloor.floorNumber }}</strong>
          </p>
          <p>
            {{ $t('invitation.parkOn') }}: <strong> {{ slot.parkingSlotNumber }}</strong>
          </p>
          <!-- </div> -->
          <div class="parking-map-wrapper is-unclickable" ref="mapWrapper">
            <!-- <div class="img-square"></div> -->
            <parking-map
              v-if="loggedIn && slot && parking"
              @hook:mounted="addResizer"
              :selectedSlot="[slot.x, slot.y]"
              :lotId="reservation.parkingLotId"
              :floorId="slot.parkingFloorId"
              :fitWidth="containerWidth - 60"
              :pathOnLoad="true"
            />
          </div>
        </section>
      </div>
      <modal v-model="openModal" v-if="loggedIn" class="has-text-center">
        <user-vehicles>
          <button
            class="button is-primary is-outlined mb-1 has-text-right"
            @click="openModal = false"
          >
            Gotowe
          </button>
        </user-vehicles>
      </modal>
    </scroll-box>

    <div
      class="is-overlay main-loader is-flex is-justify-content-center is-align-items-center"
      v-if="loading"
    >
      <button class="button is-loading is-inverted is-outlined is-white"></button>
    </div>
  </div>
</template>
<script>
import QrcodeVue from 'qrcode.vue';
import { mapMutations } from 'vuex';
import moment from 'moment';
import 'moment/locale/pl';
import ScrollBox from '@/components/ui/layout/ScrollBox';
import ParkingMap from '@/components/ParkingMap';
import { getReservationsByIds } from '@/utils/api/parking_slot_reservation';
import { loginAsGuest } from '@/utils/api/auth';
import { getLots } from '@/utils/api/parking_lot';
import { getSlotsByIds } from '@/utils/api/parking_slot';
import Modal from '@/components/ui/layout/Modal';
import UserVehicles from '@/components/UserVehicles';

moment.locale('pl');

export default {
  data() {
    return {
      val: [''],
      containerWidth: window.innerWidth,
      loading: false,
      loggedIn: false,
      notFound: false,
      reservation: null,
      lots: [],
      openModal: false,
      user: null,
      slot: null,
    };
  },

  components: {
    QrcodeVue,
    ScrollBox,
    ParkingMap,
    Modal,
    UserVehicles,
  },
  computed: {
    parking() {
      if (!this.reservation) return null;
      return this.lots.find((e) => e.id === this.reservation.parkingLotId) || {};
    },
    day() {
      return this.reservation ? moment(this.reservation.startDate).format('DD MMMM YYYY') : '';
    },
    hours() {
      return this.reservation
        ? `${moment(this.reservation.startDate).format('HH:mm')}-${moment(
            this.reservation.endDate
          ).format('HH:mm')}`
        : '';
    },
    mapLink() {
      if (!this.parking) return null;
      return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
        this.parking.spaceos_address
      )}`;
    },
  },
  methods: {
    ...mapMutations({ setToken: 'auth/setGuestToken' }),
    async fetchToken() {
      try {
        const response = await loginAsGuest(
          Number(this.$route.params.userId),
          this.$route.params.secret
        );
        console.log(response);
        this.setToken(response.data.accessToken);
        this.loggedIn = true;
        return true;
      } catch (err) {
        console.log('err', err);
        this.notFound = true;
        return false;
      }
    },
    async fetchReservation() {
      try {
        const reservation = await getReservationsByIds([Number(this.$route.params.reservationId)]);
        [this.reservation] = reservation.data;
        return true;
      } catch (err) {
        console.log('err', err);
        this.notFound = true;
        return false;
      }
    },
    async fetchLots() {
      try {
        const lots = await getLots();
        this.lots = lots.data;
        return true;
      } catch (err) {
        console.log('err', err);
        this.notFound = true;
        return false;
      }
    },
    async fetchSlot() {
      try {
        const slots = await getSlotsByIds([this.reservation.parkingSlotId]);
        [this.slot] = slots.data;
        return true;
      } catch (err) {
        console.log('err', err);
        this.notFound = true;
        return false;
      }
    },
    addResizer() {
      this.containerWidth = this.$refs.mapWrapper.clientWidth;
      window.addEventListener('resize', () => {
        this.containerWidth = this.$refs.mapWrapper.clientWidth;
      });
    },
  },
  async mounted() {
    this.loading = true;
    if (await this.fetchToken()) {
      await this.fetchReservation();
      await this.fetchSlot();
      await this.fetchLots();
    }
    this.loading = false;
  },
  beforeUnmount() {
    this.setToken(null);
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/variables.scss';
.img-square {
  height: 250px;
  width: auto;
  background-color: lightgrey;
}
.parking-map-wrapper {
  margin: 20px !important;
}
.main-loader {
  z-index: 30;
  &:before {
    opacity: 0.4;
    content: '';
    @extend .has-background-primary;
    @extend .is-overlay;
  }
  button {
    pointer-events: none;
    border: none;
    transform: scale(2.5);
  }
}
</style>
