<template>
  <div>
    <div class="mb-6 is-flex is-flex-direction-column is-align-items-stretch">
      <current-parking v-if="parking && parking.id" class="mb-6" />
      <router-link
        v-if="!parking || !parking.id"
        class="button is-link is-outlined mb-2"
        to="/parking/select"
      >
        {{ $t('menu.selectParking') }}
      </router-link>
      <router-link class="button is-primary mb-5" to="/company/users">
        {{ $t('selectListItems.users') }}
      </router-link>
      <router-link class="button is-primary mb-5" to="/company/repeatable-reservations">
        {{ $t('selectListItems.recurentRes') }}
      </router-link>
    </div>
    <go-back />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import CurrentParking from '@/components/CurrentParking';
import GoBack from '@/components/ui/GoBack';

export default {
  components: {
    CurrentParking,
    GoBack,
  },
  computed: {
    ...mapGetters({ parking: 'parking/getCurrentParking' }),
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/variables.scss';
.logo {
  height: 1.5rem;
  @include mobileAndShort {
    display: none;
  }
}
</style>
