import { httpClient } from './httpClient';

const END_POINT = '/parking_slot';

export const createParkingSlot = (x, y, floorId, parkingSlotNumber, direction) =>
  httpClient.run.post(
    `${END_POINT}/createParkingSlot`,
    {},
    {
      params: {
        parkingFloorId: floorId,
        parkingSlotNumber,
        x,
        y,
        direction,
      },
    }
  );

export const assignToCompany = (parkingSlotId, companyId) =>
  httpClient.run.post(
    `${END_POINT}/assignParkingSlotToCompany`,
    {},
    {
      params: {
        companyId,
        parkingSlotId,
      },
    }
  );
export const deassignToCompany = (parkingSlotId, companyId) =>
  httpClient.run.post(
    `${END_POINT}/deassignParkingSlotToCompany`,
    {},
    {
      params: {
        companyId,
        parkingSlotId,
      },
    }
  );

export const deleteParkingSlot = (id) => {
  console.log(id);
  return httpClient.run.delete(`${END_POINT}/deleteParkingSlot/${id}`);
};

export const getSlots = (parkingLotId, companyId) =>
  httpClient.run.get(`${END_POINT}/listParkingSlots`, {
    params: {
      parkingLotId,
      companyId,
      // reservationsStartsAfterDate: `0001-01-01 00:00:00`,
      // reservationsStartsBeforeDate: `0001-01-01 00:00:00`,
      // reservationsEndsAfterDate: `0001-01-01 00:00:00`,
      // reservationsEndsBeforeDate: `0001-01-01 00:00:00`,
    },
  });

export const getSlotsByIds = (whitelistIds) =>
  httpClient.run.get(`${END_POINT}/listParkingSlots`, {
    params: {
      whitelistIds,
    },
  });
