<template>
  <div class="has-text-centered">
    <h2 class="title is-5">{{ $t('error.404') }}</h2>
    <go-back to="/" text="Menu główne" :left="false" />
  </div>
</template>
<script>
import GoBack from '@/components/ui/GoBack';

export default {
  components: {
    GoBack,
  },
};
</script>
