import store from '@/store';

export default async function authGuard(to) {
  const tokens = store.getters['auth/getTokens'];
  console.log('tokens', tokens);
  console.log(to.path);
  console.log(to.meta.requiresAuth);
  console.log(to.query);

  if (to.query.access_token) {
    console.log('detected spaceos token');
    // if (to.query.access_token !== tokens.grantedBySpaceosToken) {
    const success = await store.dispatch('auth/loginBySpaceosToken', to.query.access_token);
    if (success !== true) {
      console.log('cannot login by spaceos token');
      return {
        path: '/auth/login',
        query: {
          redirect: to.path,
        },
      };
    }
    console.log('logged in by spaceos token');
    if (to.path === '/auth/login') {
      return {
        path: '/auth/login',
        query: {
          redirect: '/',
        },
      };
    }
    // } else {
    //   console.log('current API token already granted by this token')
    // }
  }

  if (to.meta.requiresAuth) {
    if (!tokens.API) {
      console.log('no tokens');
      return {
        path: '/auth/login',
        query: {
          redirect: to.path,
        },
      };
    }
  }

  if (to.query.location_id) {
    const currentParking = store.getters['parking/getCurrentParking'];
    if (to.query.location_id !== currentParking.spaceos_location_id) {
      store.dispatch('parking/setParkingByLocationId', to.query.location_id);
    }
  }

  return true;
}
