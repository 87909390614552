<template>
  <div>
    <div class="mb-4">
      <h2 class="title is-6">{{ $t('guest.title') }}</h2>
      <h3 class="subtitle is-6">{{ $t('guest.subtitle') }}</h3>
      <InputForm v-model="inputValues" ref="inputForm"> </InputForm>
    </div>
    <div class="columns is-mobile">
      <div class="column is-half has-text-left">
        <button
          class="button is-primary is-outlined mb-1 has-text-right"
          @click="this.$emit('close')"
        >
          {{ $t('Vehicle.cancel') }}
        </button>
      </div>

      <div class="column is-half has-text-right">
        <button
          class="button is-primary has-text-right"
          @click="save"
          :class="{ 'is-loading': loading }"
        >
          {{ $t('reservations.addGuest') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import InputForm from '../InputForm';
import { assignGuest } from '@/utils/api/parking_slot_reservation';

export default {
  data() {
    return {
      inputValues: {
        email: {
          value: '',
          label: 'email',
          placeholder: 'type here guests email',
          type: 'text',
          validators: [{ name: 'isEmail' }],
        },
      },
      loading: false,
      editIdx: null,
    };
  },
  props: {
    res: Object,
  },
  components: {
    InputForm,
  },
  computed: {
    guest: Object,
  },

  methods: {
    async save() {
      console.log('save');
      if (this.$refs.inputForm.validateAll()) {
        this.loading = true;
        const response = await assignGuest(this.res.id, this.inputValues.email.value);
        if (response.status === 201)
          this.$emit('guestAdded', { email: this.inputValues.email.value });
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/variables.scss';
.modal-content {
  max-width: calc($mobile-box-width - 50px);
}
.car {
  &:hover {
    @extend .has-background-primary-light;
  }
  &.current {
    border: 1px solid $primary;
  }
}
</style>
