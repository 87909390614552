<template>
  <scroll-box>
    <div>
      <div>
        <h2 class="title is-5 mb-0">
          {{ $t('qr.yourQr') }}
          <span @click="openModal = !openModal" class="icon ml-1 has-text-dark">
            <FaIcon icon="info-circle" />
          </span>
        </h2>
        <span class="subtitle is-6">
          {{ $t('menu.scanYourCode') }}
          <br />
          {{ $t('qr.info') }}
        </span>
      </div>
      <modal v-model="openModal">
        <p>{{ $t('helpers.addVehicle.description') }}</p>
        <router-link class="button is-link mt-2" to="/user/vehicles">
          <span>
            {{ $t('helpers.addVehicle.button') }}
          </span>
          <span class="icon">
            <FaIcon icon="chevron-right" />
          </span>
        </router-link>
      </modal>
      <div>
        <Skeleton
          v-if="this.imgSrc === null"
          :elements="['qr-code']"
          :no-box="true"
          :no-padding="true"
          class="mt-4"
        ></Skeleton>
        <img v-else class="qr-code" :src="`data:image/jpeg;charset=utf-8;base64, ${this.imgSrc}`" />
      </div>
      <div class="columns is-mobile">
        <div class="column">
          <go-back />
        </div>
      </div>
    </div>
  </scroll-box>
</template>
<script>
import GoBack from '@/components/ui/GoBack';
import Modal from '@/components/ui/layout/Modal';
import { getQr } from '@/utils/api/qr';
import Skeleton from '@/components/ui/layout/Placeholders/Skeleton';
import ScrollBox from '@/components/ui/layout/ScrollBox';

export default {
  components: {
    GoBack,
    Modal,
    Skeleton,
    ScrollBox,
  },
  data() {
    return {
      openModal: false,
      imgSrc: null,
    };
  },

  async mounted() {
    const response = await getQr();
    this.imgSrc = Buffer.from(response.data, 'binary').toString('base64');
  },
};
</script>
<style lang="scss" scoped>
.qr-code {
  width: 100% !important;
  height: auto !important;
  max-width: 450px;
  path {
    fill: red !important;
  }
}

section {
  max-height: 600px;
}
</style>
