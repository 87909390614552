<template>
  <canvas ref="canvas" :height="canvasHeight" :width="canvasWidth"></canvas>
</template>

<script>
import calculatePathFromArray from '@/utils/navigation';

export default {
  data() {
    return {
      canvas: null,
    };
  },
  props: {
    blockSize: Number,
    floorDetails: Object,
  },
  computed: {
    canvasHeight() {
      return this.floorDetails.yDimension * this.blockSize;
    },
    canvasWidth() {
      return this.floorDetails.xDimension * this.blockSize;
    },
    entrance() {
      return this.floorDetails.parkingLayout2d
        .find((arr) => arr.some((e) => e.spaceType === 'entrance'))
        .find((e) => e.spaceType === 'entrance');
    },
  },
  methods: {
    clear() {
      this.canvas.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
    },
    showPath(slotPosition) {
      const targetId = `${slotPosition[0]}:${slotPosition[1]}`;
      const startId = `${this.entrance.x}:${this.entrance.y}`;
      const nodes = calculatePathFromArray(this.floorDetails.parkingLayout2d, startId, targetId);
      console.log(nodes);
      this.$nextTick(() => {
        this.drawPath(nodes);
      });
    },

    getPosition(node) {
      const position = node.id.split(':');
      const x = parseInt(position[0], 10) * this.blockSize + this.blockSize / 2;
      const y = parseInt(position[1], 10) * this.blockSize + this.blockSize / 2;
      return [x, y];
    },
    drawPath(nodes) {
      // const grad = this.canvas.createLinearGradient(0, 0, this.canvasWidth, this.canvasHeight);
      // const width = this.floorDetails.xDimension;
      // for (let i = 0; i < width; i += 1) {
      //   console.log(i, width, (i + 0.5) / width);
      //   grad.addColorStop(i / width, '#fff');
      //   grad.addColorStop((i + 0.5) / width, '#213A8E');
      // }

      // this.canvas.strokeStyle = grad;
      this.canvas.strokeStyle = '#213A8E';
      this.canvas.setLineDash([10]);
      this.canvas.lineWidth = this.blockSize * 0.1;
      this.canvas.lineCap = 'round';
      this.canvas.lineJoin = 'round';
      this.clear();
      this.canvas.beginPath();
      for (let i = nodes.length - 1; i > 0; i -= 1) {
        const node = nodes[i];
        const next = nodes[i - 1];

        this.canvas.moveTo(...this.getPosition(node));
        this.canvas.lineTo(...this.getPosition(next));
        this.canvas.stroke();
      }
    },
  },
  mounted() {
    const c = this.$refs.canvas;
    const ctx = c.getContext('2d');
    this.canvas = ctx;
  },
};
</script>
