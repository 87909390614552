<template>
  <div>
    <h2 class="title is-6 mb-2">
      {{ $t('reservations.addTitle') }}
    </h2>
    <span class="subtitle is-6">{{ $t('reservations.addHelper') }}</span>
    <div class="has-text-grey-light has-text-left block my-4">
      <div class="columns is-mobile">
        <div class="column has-text-centered">
          <span class="icon-text">
            <span class="icon">
              <FaIcon icon="clock" />
            </span>
            <span>
              {{ formatter(duration, true) }}
            </span>
          </span>
        </div>
        <div class="column has-text-centered">
          <span class="icon-text">
            <span>
              {{ slotObj.parkingSlotNumber }}
            </span>
            <span class="icon spot-icon">
              <FaIcon icon="angle-double-down" />
            </span>
          </span>
        </div>
      </div>
    </div>
    <div class="columns is-mobile block my-4">
      <div class="column is-half has-text-centered">
        <label class="label is-capitalized"
          ><small class="">{{ $t('time.from') }}: </small
          ><small>{{ formatter(value[0]) }}</small></label
        >
        <div class="field has-addons is-narrow is-justify-content-center">
          <div class="control">
            <button
              @click="changeStart(-1)"
              class="button is-small is-primary"
              :disabled="!canChangeStart(-1)"
            >
              -
            </button>
          </div>
          <div class="control">
            <button
              @click="changeStart(1)"
              class="button is-small is-primary ml-1"
              :disabled="!canChangeStart(1)"
            >
              +
            </button>
          </div>
        </div>
      </div>
      <div class="column is-half has-text-centered">
        <label class="label is-capitalized"
          ><small class="">{{ $t('time.to') }}: </small
          ><small>{{ formatter(value[1]) }}</small></label
        >
        <div class="field has-addons is-narrow is-justify-content-center">
          <div class="control">
            <button
              @click="changeEnd(-1)"
              class="button is-small is-primary"
              :disabled="!canChangeEnd(-1)"
            >
              -
            </button>
          </div>
          <div class="control">
            <button
              @click="changeEnd(1)"
              class="button is-small is-primary ml-1"
              :disabled="!canChangeEnd(1)"
            >
              +
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="block mt-4 mb-6">
      <vue-slider
        v-model="value"
        :min="min"
        :max="max"
        :enable-cross="false"
        :interval="interval"
        :min-range="interval"
        :marks="marks"
        :tooltip-formatter="formatter"
        :contained="true"
        :dot-size="24"
      >
      </vue-slider>
    </div>
    <div class="block" v-if="userIs(['superadmin', 'parking_administration', 'company_admin'])">
      <div class="columns is-mobile">
        <div class="column">
          <small>Powtarzaj:</small><br />
          <div class="select is-small">
            <select v-model="repeatEvery">
              <option :value="null" default>{{ $t('reservations.repeat.none') }}</option>
              <option value="day" default>
                {{ $t('reservations.repeat.everyday') }}
              </option>
              <option value="week" default>
                {{ $t('reservations.repeat.every') }}{{ ' ' }}{{ $t('reservations.repeat.week') }}
              </option>
            </select>
          </div>
        </div>
        <div class="column">
          <small>Przypisz do:</small><br />
          <drop-down v-model="userId"></drop-down>
        </div>
      </div>
    </div>
    <div class="block">
      <button class="button is-primary" @click="add">
        {{ $t('reservations.add') }}
      </button>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import VueSlider from 'vue-slider-component';
import { mapGetters, mapActions } from 'vuex';
import m from '@/utils/math';
import time from '@/utils/time';
import { createReservation } from '@/utils/api/parking_slot_reservation';
import userMixin from '@/mixins/userMixin';
import DropDown from '@/components/UsersDropDown';

export default {
  setup(props) {
    const interval = ref(0.25);
    const min = ref(time.toNum(props.res.start, interval.value, 'up'));
    const max = ref(time.toNum(props.res.end, interval.value, 'down'));
    const value = ref([min.value, max.value]);
    return {
      value,
      min,
      max,
      interval,
      userId: null,
      repeatEvery: null,
    };
  },
  components: {
    VueSlider,
    // eslint-disable-next-line vue/no-unused-components
    DropDown,
  },
  props: {
    res: Object,
    slotObj: Object,
    day: String,
  },
  mixins: [userMixin],
  computed: {
    ...mapGetters({
      users: 'admin/getUsers',
    }),
    duration() {
      return this.value[1] - this.value[0];
    },
  },
  methods: {
    ...mapActions({
      fetchUsers: 'admin/fetchUsersIfEmpty',
    }),
    formatter(val, units = false) {
      let hour = Math.floor(val);
      let mins = ((Math.floor(val * 100) % 100) / 100) * 60;
      if (units) {
        let ret = '';
        if (hour) ret += `${hour}${this.$t('time.h')}`;
        if (hour && mins) ret += ' ';
        if (mins) ret += `${mins}${this.$t('time.m')}`;
        return ret;
      }
      if (hour < 10) hour = `0${hour}`;
      if (mins < 10) mins = `0${mins}`;
      return `${hour}:${mins}`;
    },
    marks(val) {
      const step = m.floorToFraction((this.max - this.min) / 4, this.interval);
      const isEach = val % step === 0 && val >= this.min + step && val <= this.max - step;
      const firstOrLast = val === this.min || val === this.max;
      return isEach || firstOrLast
        ? {
            label: this.formatter(val),
            labelActiveStyle: {
              color: '#213A8E',
            },
          }
        : false;
    },
    changeStart(num) {
      this.value = [(this.value[0] += num * this.interval), this.value[1]];
    },
    canChangeStart(num) {
      const val = this.value[0] + num * this.interval;
      return this.min <= val && this.max >= val && this.value[1] - val >= this.interval;
    },
    changeEnd(num) {
      this.value = [this.value[0], (this.value[1] += num * this.interval)];
    },
    canChangeEnd(num) {
      const val = this.value[1] + num * this.interval;
      return this.min <= val && this.max >= val && val - this.value[0] >= this.interval;
    },
    async add() {
      this.loading = true;
      const res = await createReservation({
        startDate: `${this.day} ${this.formatter(this.value[0])}`,
        endDate: `${this.day} ${this.formatter(this.value[1])}`,
        parkingSlotId: this.slotObj.id,
        overrideUserId: this.userId,
        repeatEvery: this.repeatEvery,
      });
      this.loading = false;
      this.$emit('reservationAdded', res.data);
    },
  },
  mounted() {
    if (this.userIs(['superadmin', 'parking_administration', 'company_admin'])) {
      this.fetchUsers();
    }
  },
};
</script>
<style lang="scss">
@import '@/assets/variables.scss';
$themeColor: $primary;
@import '~vue-slider-component/lib/theme/default.scss';
</style>
<style lang="scss" scoped>
.vue-slider {
  &,
  * {
    box-sizing: content-box;
  }
}
.time-input {
  width: 60px;
}
</style>
