<template>
  <section
    class="
      section
      has-text-centered
      is-flex is-flex-direction-column is-align-items-stretch is-justify-content-center
    "
  >
    <router-view v-slot="{ Component }">
      <transition name="fade" appear>
        <router-link to="/" style="transition-delay: 0.5s" class="logo-wrapper">
          <img src="@/assets/img/parkme_logo_white.svg" alt="" class="logo" />
        </router-link>
      </transition>
      <transition name="height" appear>
        <div class="h-full box-wrapper is-relative is-flex-grow-1">
          <div
            class="
              box
              container
              main-box
              has-background-light
              is-flex-grow-5-tablet is-flex is-flex-direction-column is-justify-content-center
            "
          >
            <component :is="Component" />
            <div
              class="
                is-overlay
                router-loader
                is-flex is-justify-content-center is-align-items-center
              "
              v-if="routerLoading"
            >
              <button class="button is-loading is-inverted is-outlined is-white"></button>
            </div>
          </div>
          <transition name="slide-out">
            <div
              class="
                is-overlay
                loading-overlay
                has-background-primary
                m-0
                is-flex
                is-flex-direction-column
                is-justify-content-center
                is-align-items-center
                is-hidden-high-tablet
              "
              v-if="!isLoaded || globalLoading"
            >
              <div class="image-ink is-overlay has-background-primary"></div>
              <transition name="fade-in" append>
                <div
                  v-show="logoLoaded"
                  class="has-background-primary is-relative p-3 loader-logo-wrapper is-clipped"
                >
                  <div class="image is-64x64 is-flex">
                    <img
                      src="@/assets/img/parkme_logo_white.svg"
                      alt=""
                      class="logo-small"
                      @load="logoLoaded = true"
                    />
                  </div>
                  <loader-effect :light="true" :rotate="true" />
                </div>
              </transition>
            </div>
          </transition>
        </div>
      </transition>
    </router-view>
  </section>
</template>
<script>
import { mapGetters } from 'vuex';
import LoaderEffect from '@/components/ui/layout/Placeholders/LoaderEffect';

export default {
  data() {
    return {
      isLoaded: false,
      logoLoaded: false,
    };
  },
  components: {
    LoaderEffect,
  },
  computed: {
    ...mapGetters({ globalLoading: 'getLoading', routerLoading: 'getRouterLoading' }),
  },
  mounted() {
    setTimeout(() => {
      this.isLoaded = true;
    }, 2000);
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/variables.scss';
$dummy-margin: calc((100vw / 750) * 146 * (36 / 146));
section {
  // min-height: var(--app-height);
  background-image: url(/assets/img/parkings/garage.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.5;
    pointer-events: none;
    background: $primary;
    z-index: -1;
  }
  .main-box {
    max-width: $mobile-box-width !important;
    max-height: 70vh;
  }
  @include mobileAndShort {
    padding: 0 !important;
    background: $light;
    &:before {
      content: unset;
    }
    .box-wrapper {
      margin-bottom: -$dummy-margin;
    }
    .main-box {
      box-shadow: none;
      height: 100%;
      padding-top: 0;
      padding-bottom: 0;
      max-height: var(--app-height);
      width: 100vw;
      // margin-bottom: -$dummy-margin;
    }
    .logo-wrapper {
      display: none;
    }
  }
}
.router-loader {
  z-index: 200;
  &:before {
    opacity: 0.4;
    content: '';
    @extend .has-background-primary;
    @extend .is-overlay;
  }
  button {
    pointer-events: none;
    border: none;
    transform: scale(2.5);
  }
}
.logo {
  max-width: 150px;
  @extend .is-align-self-center, .mb-6, .mt-4;
}
.loading-overlay {
  z-index: 20;
  background-image: url(/assets/img/parkings/garage.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @include highTablet {
    display: none !important;
  }
  .image-ink {
    opacity: 0.2;
  }
  .loader-logo-wrapper {
    border-radius: 12px;
    box-shadow: 0px 0 0 2px $grey;
  }
}
.fade-enter-active {
  animation: fade-in-and-shrink 2s;
  overflow: hidden;
}

.height-enter-active {
  @include highTablet {
    animation: wait-and-grow 2s;
  }
}
.slide-out {
  &-enter {
    &-from {
      transition: transform 1s;
      transform: translateY(-100%);
    }
    &-active {
      transition: transform 1s;
    }
    &-to {
      transform: translateY(0);
    }
  }
  &-leave {
    &-active {
      transition: transform 1s;
      transform: translateY(0);
    }
    &-to {
      transform: translateY(-100%);
    }
  }
}
.fade-in {
  &-enter {
    &-active {
      opacity: 1;
      transition: opacity 1s;
    }
    &-from {
      opacity: 0;
    }
  }
}

@keyframes fade-in-and-shrink {
  0% {
    opacity: 0;
    transform: scale(1.5);
  }

  50% {
    opacity: 1;
    transform: scale(1.5);
  }

  80% {
    transform: scale(1);
  }

  100% {
    transform: scale(1);
  }
}
@keyframes wait-and-grow {
  0% {
    opacity: 0;
    max-height: 0;
  }

  50% {
    max-height: 0;
  }

  80% {
    opacity: 0;
    max-height: 300px;
  }

  100% {
    opacity: 1;
  }
}
</style>
