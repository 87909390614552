import moment from 'moment';
import { getUserDaysOfAbsence } from '@/utils/api/absence';

export default {
  state() {
    return {
      absence: [],
      loaded: false,
    };
  },
  mutations: {
    setAbsence: (state, absence) => {
      state.absence = absence;
      state.loaded = true;
    },
  },
  getters: {
    getLoaded: (state) => state.loaded,
    getAbsence: (state) => state.absence,
  },
  actions: {
    async fetchAbsence({ commit }) {
      const start = moment().startOf('day').format('YYYY-MM-DD HH:mm:ss');
      const end = moment().add(100, 'years').format('YYYY-MM-DD HH:mm:ss');
      const response = await getUserDaysOfAbsence(start, end);
      commit('setAbsence', response.data);
      return response.data;
    },
  },
  namespaced: true,
};
