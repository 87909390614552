import { getSlots } from '@/utils/api/parking_slot';

export default {
  state() {
    return {
      slots: [],
      companySlots: [],
      loaded: false,
      loading: false,
    };
  },
  mutations: {
    setSlots: (state, slots) => {
      state.slots = slots;
      state.loaded = true;
    },
    setCompanySlots: (state, slots) => {
      state.companySlots = slots;
    },
    setLoading: (state, loading) => {
      state.Loading = loading;
    },
  },
  getters: {
    getLoaded: (state) => state.loaded,
    getLoading: (state) => state.loading,
    getSlots: (state) => state.companySlots,
    getSlotById: (state) => (slotId) => state.slots.find((e) => e.id === slotId),
  },
  actions: {
    async fetchSlots({ commit, rootGetters }) {
      commit('setLoading', true);
      if (rootGetters['auth/user/getUser'].spaceosCompany === null)
        return this.dispatch('notify', { title: 'Not assigned to the company' }, { root: true });
      const companyId = rootGetters['auth/user/getUser'].spaceosCompany.id;
      const parkingId = rootGetters['parking/getCurrentParking'].id;

      const responseCompany = await getSlots(parkingId, companyId);
      const response = await getSlots(parkingId);
      commit('setCompanySlots', responseCompany.data);
      commit('setSlots', response.data);
      commit('setLoading', false);
      return response.data;
    },
    async fetchSlotsIfEmpty({ dispatch, getters }) {
      if (!getters.getLoading && !getters.getLoaded) {
        return dispatch('fetchSlots');
      }
      return getters.getSlots;
    },
  },
  namespaced: true,
};
